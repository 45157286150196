@use 'sass:math';

.p-chips {
    &:not(.p-disabled):hover {
        .p-chips-multiple-container {
            border-color: $inputHoverBorderColor;
        }
    }

    &:not(.p-disabled).p-focus {
        .p-chips-multiple-container {
            @include focused-input();
        }
    }

    .p-chips-multiple-container {
        padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
        gap: $inlineSpacing;

        .p-chips-token {
            padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
            margin-right: $inlineSpacing;
            background: $chipBg;
            color: $chipTextColor;
            border-radius: $chipBorderRadius;

            &.p-focus {
                background: $chipFocusBg;
                color: $chipFocusTextColor;
            }

            .p-chips-token-icon {
                margin-left: $inlineSpacing;
            }
        }

        .p-chips-input-token {
            padding: math.div(nth($inputPadding, 1), 2) 0;
                
            input {
                font-family: var(--font-family);
                font-feature-settings: var(--font-feature-settings, normal);
                font-size: $inputTextFontSize;
                color: $textColor;
                padding: 0;
                margin: 0;
            }
        }
    }
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
    @include invalid-input();
}

p-chips.p-chips-clearable {
    .p-inputtext {
        padding-right: nth($inputPadding, 2) + $primeIconFontSize;
    }

    .p-chips-clear-icon {
        color: $inputIconColor;
        right: nth($inputPadding, 2);
    } 
}
.rp-completed-chip {
    font-size: 10px;
    color: #1FA822;
    background: #DDFFDD;
    padding: 5px;
    border-radius: 20px;
    border: 1px solid #1FA822;
    text-align: center;
}

.rp-inprogress-chip {
    font-size: 10px;
    color: #df9304;
    background: #df930421;
    padding: 5px;
    border-radius: 20px;
    border: 1px solid #df9304;
    white-space: nowrap;
    text-align: center;
}

.rp-pending-chip {
    font-size: 10px;
    color: #c70000;
    background: #c7000021;
    padding: 5px;
    border-radius: 20px;
    border: 1px solid #c70000;
    text-align: center;
}
.rp_status__label {
    font-size: 12px;
    border-radius: 20px;
    padding: 3px 9px 3px 25px;
    position: relative;    
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    width: 100%;

    &:before {
        content: "";
        width: 7px;
        height: 7px;
        position: absolute;
        left: 9px;
        top: calc(50% - 3px);
        right: 0;
        border-radius: 100%;
    }

    &.status-pending{
        border: 1px solid $srt-neutral-3500;
        background-color: $srt-neutral-3400;
        color: $srt-neutral-3500;
    
        &:before {
            background-color: $srt-neutral-3500;
        }
    }

    &.status-approved{
        border: 1px solid $srt-neutral-1700;
        background-color: $srt-neutral-1800;
        color: $srt-neutral-1700;
    
        &:before {
            background-color: $srt-neutral-1700;
        }
    }

    &.status-rejected{
        border: 1px solid $srt-neutral-1500;
        background-color: $srt-neutral-1600;
        color: $srt-neutral-1900;
    
        &:before {
            background-color: $srt-neutral-1500;
        }
    }
}
.rp-info-chips {
    background-color: #229AFF;
    color: #fff;
    border-radius: 15px;
    padding: 0.25rem 0.75rem;
    font-weight: 400;
    margin: 0 0.25rem 0.25rem 0;
    display: inline-flex;
  }