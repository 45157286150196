// Theme Specific Variables
$primaryColor: #015296;
$primaryDarkColor: #319dd6;
$primaryDarkerColor: #015296;
$primaryWhiteColor: #ffffff;
$primaryBlackColor: #000;
$primaryGrayColor: #E2E2EA;
$primaryBlackDark : #09090b;
$primaryGrayDark:#2e2e2e;
$srt-black: #000;
$srt-white: #fff;
$srt-secondary: #E6EBF1;
$srt-neutral-100: #F4F7FC;
$srt-neutral-200: #F9FAFC;
$srt-neutral-300: #171C26;
$srt-neutral-400: #00072B;
$srt-neutral-500: #464f60;
$srt-neutral-600: #687182;
$srt-neutral-700: #F7F9FC;
$srt-neutral-800: #959494;
$srt-neutral-900: #D2D2D2;
$srt-neutral-1000: #868FA0;
$srt-neutral-1100: #C9DAE9;
$srt-neutral-1200: #344054;
$srt-neutral-1300: #D0D5DD;
$srt-neutral-1400: #191D23;
$srt-neutral-1500: #E41111;
$srt-neutral-1600: #FDECEC;
$srt-neutral-1700: #0DA04B;
$srt-neutral-1800: #EEFFF5;
$srt-neutral-1900: #E4CF11;
$srt-neutral-2000: #FFF9F4;
$srt-neutral-2100-1: #1A1A1A;
$srt-neutral-2200-1: #F61111;
$srt-neutral-2100: #C7E6F4;
$srt-neutral-2200: #FAFAFA;
$srt-neutral-2300: #AFAFAF;
$srt-neutral-2400: #E2E2EA;
$srt-neutral-2500: #494949;
$srt-neutral-2600: #F0F8FF;
$srt-neutral-2700: #C3E3FF;
$srt-neutral-2800: #E4EEFF;
$srt-neutral-2900: #3C8EE3;
$srt-neutral-3000: #F5F5F5;
$srt-neutral-3100: #D6DDE5;
$srt-neutral-3200: #F4F4F7;
$srt-neutral-3300: #F9F9F9;
$srt-neutral-3400: #FFF7EF;
$srt-neutral-3500: #FF9900;
$srt-neutral-3600: #319DD6;
$srt-neutral-3700: #dee2e6;
$srt-neutral-3800: #2196F3;
$srt-neutral-3900: #FCFCFD;
$srt-neutral-4000: #9757D7;
$srt-neutral-4100: #EF466F;
$srt-neutral-4200: #45B26B;
$srt-neutral-4300: #CC5187;
$srt-neutral-4400: #6076B0;
$srt-neutral-4500: #1CAB98;
$srt-neutral-4600: #CB9DF1;
$srt-neutral-4700: #32C3B4;
$srt-neutral-4800: #F58400;
$srt-neutral-4900: #06365E;
$srt-neutral-5000: #171725;
$srt-neutral-5100: #696974;
$srt-neutral-5200: #1A1C1E;
$srt-neutral-5300: #6c757d;
$srt-neutral-5400: #F1F1F1;
$srt-neutral-5500: #1FA822;
$srt-neutral-5600: #F0FFF0;
$rp-primary-blue-800:#015296;
$rp-primary-blue-700:#0059A9;
$rp-primary-blue-600:#0071D0;
$rp-primary-blue-500:#038FF4;
$rp-primary-blue-400:#2FABFF;
$rp-primary-blue-300:#77C6FF;
$rp-primary-blue-200:#B7DFFF;
$colors: (    
    "primary": $primaryColor
);

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';