.p-menubar {
    background: var(--bg-color);
    color: var(--text-color);
    line-height: 20px;

    .p-menubar-root-list {
        outline: 0 none;
        gap:5px;

        >.p-menuitem {
            @include horizontal-rootmenuitem();

            .p-menuitem-content {
                margin-right: 1px;
                margin-left: 1px;
            }

            &:last-child {
                .p-menuitem-content .p-menuitem-link .p-submenu-icon {
                    display: none;
                }

                .p-submenu-list {
                    .p-menuitem {
                        position: relative;

                        .p-submenu-list {
                            right: 100%;
                            left: unset;
                        }
                    }

                }
            }
        }

        .p-submenu-list {
            .p-menuitem {
                .p-menuitem-content .p-menuitem-link .p-submenu-icon {
                    display: block;
                }
            }
        }
    }

    .p-menuitem {
        @include menuitem();
    }

    .p-submenu-list {
        padding: $verticalMenuPadding;
        background: var(--bg-color-block);
        box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.10), -4px 4px 4px 0px rgba(0, 0, 0, 0.10);
        top: 40px;
        min-width: 14rem;
        border-radius: $borderRadius;

        li {
            .p-menuitem-content {
                border-bottom: 1px solid rgba(0, 0, 0, 0.10);
                border-radius: 10px;
            }
        }

        .p-menuitem-separator {
            border-top: $divider;
            margin: $menuSeparatorMargin;
        }

        .p-submenu-icon {
            font-size: $menuitemSubmenuIconFontSize;
        }
    }
}

@media screen and (max-width: 960px) {
    .p-menubar {
        position: relative;

        .p-menubar-button {
            display: flex;
            width: $actionIconWidth;
            height: $actionIconHeight;
            color: $horizontalMenuRootMenuitemIconColor;
            border-radius: $actionIconBorderRadius;
            transition: $actionIconTransition;

            &:hover {
                color: $horizontalMenuRootMenuitemIconHoverColor;
                background: $horizontalMenuRootMenuitemHoverBg;
            }

            &:focus {
                @include focused();
            }
        }

        .p-menubar-root-list {
            position: absolute;
            display: none;
            padding: $verticalMenuPadding;
            background: $overlayMenuBg;
            border: $overlayMenuBorder;
            box-shadow: $overlayMenuShadow;
            width: 100%;

            .p-menuitem-separator {
                border-top: $divider;
                margin: $menuSeparatorMargin;
            }

            .p-submenu-icon {
                font-size: $menuitemSubmenuIconFontSize;
            }

            .p-menuitem {
                width: 100%;
                position: static;

                .p-menuitem-content {
                    .p-menuitem-link {
                        .p-submenu-icon {
                            margin-left: auto;
                            transition: transform $transitionDuration;
                        }
                    }
                }


                &.p-menuitem-active {
                    >.p-menuitem-content {
                        >.p-menuitem-link {
                            >.p-submenu-icon {
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
            }

            .p-submenu-list {
                width: 100%;
                position: static;
                box-shadow: none;
                border: 0 none;

                .p-submenu-icon {
                    transition: transform $transitionDuration;
                    transform: rotate(90deg);
                }

                .p-menuitem-active {
                    >.p-menuitem-content {
                        >.p-menuitem-link {
                            >.p-submenu-icon {
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
            }

            .p-menuitem {
                width: 100%;
                position: static;
            }

            @include nested-submenu-indents(nth($menuitemPadding, 1), 2, 10);
        }

        &.p-menubar-mobile-active .p-menubar-root-list {
            display: flex;
            flex-direction: column;
            top: 100%;
            left: 0;
            z-index: 1;
        }
    }
}