
.img-light{
    display: block;
}

.img-dark{
    display: none;
}

.dark-theme{
    .logo{
        img{
            filter: brightness(0) invert(1);
        }
    }
    footer{
        background: url('../../../assets/images/footer-black-bg.svg') !important;
    }
    .img-light{
        display: none;
    }
    .img-dark{
        display: block;
    }
}

