.p-checkbox {
    width: $checkboxWidth;
    height: $checkboxHeight;

    .p-checkbox-box {
        border: $checkboxBorder;
        background: $inputBg;
        width: $checkboxWidth;
        height: $checkboxHeight;
        color: $textColor;
        transition: $formElementTransition;
        outline-color: transparent;

        .p-checkbox-icon {
            transition-duration: $transitionDuration;
            color: $checkboxIconActiveColor;
            font-size: $checkboxIconFontSize;
        }

        .p-icon {
            width: $checkboxIconFontSize;
            height: $checkboxIconFontSize;
        }

        &.p-highlight {
            border-color: $checkboxActiveBorderColor;
            background: $checkboxActiveBg;
        }
    }


    &.p-variant-filled {
        .p-checkbox-box {
            background-color: $inputFilledBg;

            &.p-highlight {
                background: $checkboxActiveBg;
            }
        }

        &:not(.p-checkbox-disabled) {
            .p-checkbox-box {
                &:hover {
                    background-color: $inputFilledHoverBg;
                }

                &.p-highlight:hover {
                    background: $checkboxActiveHoverBg;
                }
            }
        }
    }
}

p-checkbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box {
    @include invalid-input();
}

.p-input-filled {
    .p-checkbox {
        .p-checkbox-box {
            background-color: $inputFilledBg;

            &.p-highlight {
                background: $checkboxActiveBg;
            }
        }

        &:not(.p-checkbox-disabled) {
            .p-checkbox-box {
                &:hover {
                    background-color: $inputFilledHoverBg;
                }

                &.p-highlight:hover {
                    background: $checkboxActiveHoverBg;
                }
            }
        }
    }
}

.p-checkbox-label {
    margin-left: $inlineSpacing;
}

@if ($highlightBg ==$checkboxActiveBg) {
    .p-highlight {
        .p-checkbox {
            .p-checkbox-box {
                border-color: $checkboxIconActiveColor;
            }
        }
    }
}

p-tristatecheckbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box {
    @include invalid-input();
}

.complete-profile-dialog {
    .p-checkbox {
        margin-right: 9px;

        .p-checkbox-box {
            border-radius: 5px;
            width: 18px;
            height: 18px;

            &:focus {
                box-shadow: unset;
            }
        }
    }
}

.final-submission-details {
    .p-checkbox {
        .p-checkbox-box {
            border-radius: 5px;

            &.p-highlight {
                border-color: $srt-neutral-2900;
                background: $srt-neutral-2900;
            }
        }
    }
}

.p-login-checkbox {
    .p-checkbox-box {
        border-radius: 10px;
    }
}

.discom-dashboard {
    .common-discom-filter {
        .checkbox-group {
            width: calc(100% / 7);

            &:first-child {
                .checkbox-item {
                    border-left: 1px solid #D8DEEB;
                    border-radius: 10px 0 0 10px;
                }
            }

            &:last-child {
                .checkbox-item {
                    border-right: 1px solid #D8DEEB;
                    border-radius: 0 10px 10px 0;
                }
            }

            .checkbox-item {
                background-color: #F4F6FA;
                padding: 5px;
                min-height: 40px;
                display: flex;
                width: 100%;
                border-top: 1px solid #D8DEEB;
                border-bottom: 1px solid #D8DEEB;

                p-checkbox {
                    .p-checkbox {
                        .p-checkbox-box {
                            border-color: #015296;
                        }
                    }

                    label {
                        color: #000!important;
                    }
                }
            }

            p-checkbox {
                .p-checkbox {
                    height: fit-content;
                    width: 16px;

                    .p-checkbox-box {
                        width: 16px;
                        height: 16px;
                        border: 1px solid #000;
                        border-radius: 2px;
                    }
                }

                label {
                    font-size: 11px !important;
                    font-weight: 500 !important;
                    line-height: 1.2 !important;
                    letter-spacing: 0.07em;
                    color: #B3B3B3 !important;
                    margin-left: 10px;
                }
            }

            .checkbox-item-grp {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: fit-content;
                padding: 10px 5px;
            }
        }
    }
}

.brand-selection-vendor {
    .p-checkbox {
        width: 16px;
        height: 16px;

        .p-checkbox-box {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            border: 1px solid #015296;
            background-color: transparent;

            &.p-disabled {
                opacity: 1;
            }

            .p-icon {
                width: 10px;
                height: 10px;
            }
        }

        &.p-checkbox-checked {
            .p-checkbox-box {
                border: 1px solid #015296;
                background-color: #015296;
            }
        }
    }

    .checkbox-disabled {
        .p-checkbox.p-checkbox-checked {
            .p-checkbox-box {
                border: 1px solid #ACACAC;
                background-color: #ACACAC;
            }
        }
    }

}