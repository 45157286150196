.p-picklist {
	.p-picklist-buttons {
		padding: $panelContentPadding;
		.p-button {
			margin-bottom: $inlineSpacing;
			background-color: #c9dae9;
			border-radius: 15px;
			border:1px solid $primaryGrayColor;
			color:$primaryColor;
			position: relative;
			width: 64px;
			height: 32px;
			svg{
				display: none;
			}
			&:nth-child(1){
				&::before{
					content: "\e95a";
					position: absolute;
					left:20px;
					top:7px;
					font-family: 'icomoon' !important;
				}
				
			}
			&:nth-child(3){
				&::before{
					content: "\e95b";
					position: absolute;
					left:20px;
					top:7px;
					font-family: 'icomoon' !important;
				}				
			}
			&:nth-child(2),
			&:nth-child(4) {
				display: none;
			}
		}
	}

	.p-picklist-list-wrapper {
		background: $panelContentBg;
		border: $panelContentBorder;
		border-radius: $borderRadius;
		transition: $formElementTransition;
		outline-color: transparent;

		&.p-focus {
			@include focused-input();
		}
	}

	.p-picklist-header {
		color: $panelHeaderTextColor;
		padding: $panelHeaderPadding;
		font-weight: $panelHeaderFontWeight;

		.p-picklist-title {
			font-weight: $panelHeaderFontWeight;
		}
	}

	.p-picklist-filter-container {
		padding: $panelHeaderPadding;
		background: #F4F7FC;
		border-radius: 10px;
		border-bottom: 0 none;

		h3 {
			color: $primaryColor;
			font-size: 20px;
			font-weight: 400;
		}

		.p-picklist-filter-input {
			padding-right: nth($inputPadding, 2) + $primeIconFontSize;
		}

		.p-picklist-filter-icon {
			right: nth($inputPadding, 2);
			color: $inputIconColor;
		}
	}

	.p-picklist-list {
		color: $panelContentTextColor;
		padding: $inputListPadding;
		outline: 0 none;

		&:not(:first-child) {
			border-top: $panelContentBorder;
		}

		.p-picklist-item {
			padding: $inputListItemPadding;
			margin: $inputListItemMargin;
			border: $inputListItemBorder;
			color: $inputListItemTextColor;
			background: $inputListItemBg;
			transition: $listItemTransition;
			span{
				font-size: 14px;
				font-weight: 400;
			}

			&:first-child {
				margin-top: 0;
			}

			&:not(.p-highlight):hover {
				background: $inputListItemHoverBg;
				color: $inputListItemTextHoverColor;
			}

			&.p-focus {
				color: $inputListItemTextFocusColor;
				background: $inputListItemFocusBg;
			}

			&.p-highlight {
				color: $highlightTextColor;
				background: $highlightBg !important;

				&.p-focus {
					background: $highlightBg;
				}
			}
		}

		.p-picklist-empty-message {
			padding: $inputListItemPadding;
			color: $inputListItemTextColor;
		}

		&:not(.cdk-drop-list-dragging) {
			.p-picklist-item {
				&:not(.p-highlight):hover {
					background: $inputListItemHoverBg;
					color: $inputListItemTextHoverColor;
				}
			}
		}
	}

	&.p-picklist-striped {
		.p-picklist-list {
			.p-picklist-item:nth-child(even) {
				background: #f9fafc;

				&:hover {
					background: $inputListItemHoverBg;
				}
			}
		}
	}
}

.p-picklist-item {
	&.cdk-drag-preview {
		padding: $inputListItemPadding;
		box-shadow: $inputOverlayShadow;
		border: $inputListItemBorder;
		color: $inputListItemTextColor;
		background: $panelContentBg;
		margin: 0;
	}
}