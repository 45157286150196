.p-datatable {
    position: unset !important;

    .p-datatable-wrapper {
        padding: 0 3px 5px;
        margin-top: -1px;
    }

    .p-paginator-top {
        border-width: $tableTopPaginatorBorderWidth;
        border-radius: 0;
    }

    .p-paginator-bottom {
        border-width: $tableBottomPaginatorBorderWidth;
        border-radius: 0;
    }

    .p-datatable-header {
        background-color: $srt-neutral-100;
        border: unset;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 12px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
        margin: 0 3px;
        height: 64px;

        .p-inputtext {
            border-radius: 10px;
            // padding: .5rem 1rem .5rem 2rem;
            font-size: 14px;
            color: $srt-black;
            box-shadow: unset;
        }

        .p-input-icon {
            color: $srt-neutral-800;
        }

        .add-btn {
            .p-button {
                height: 100%;
                padding: 0 12px;

                .p-button-icon {
                    font-size: 14px;

                    @media screen and (max-width: 1400px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .p-datatable-footer {
        background: $tableFooterBg;
        color: $tableFooterTextColor;
        border: $tableFooterBorder;
        border-width: $tableFooterBorderWidth;
        padding: $tableFooterPadding;
        font-weight: $tableFooterFontWeight;
    }

    .p-datatable-table {
        table-layout: fixed;
        width: 100%;
    }

    .p-datatable-thead {
        tr {
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
            border-radius: 0 0 10px 10px;

            th {
                background-color: $srt-neutral-100 !important;
                padding: 12px 8px;
                color: $srt-neutral-500;
                font-size: 13px;
                font-weight: 600;
                line-height: 1.5;
                border: unset;
                word-break: break-word;
                text-align: left;
                // white-space: nowrap;

                &:first-child {
                    border-bottom-left-radius: 10px;
                    padding: 9px 12px 9px 22px;
                    color: $srt-neutral-300;
                    // width: 80px;
                }

                &:last-child {
                    border-bottom-right-radius: 10px;
                    padding: 9px 12px 9px 12px;
                }

                .p-icon {
                    width: 12px;
                    height: 12px;
                    color: $srt-neutral-500;
                }
            }
        }
    }

    .p-datatable-tfoot>tr>td {
        text-align: $tableCellContentAlignment;
        padding: $tableFooterCellPadding;
        border: $tableFooterCellBorder;
        border-width: $tableFooterCellBorderWidth;
        font-weight: $tableFooterCellFontWeight;
        color: $tableFooterCellTextColor;
        background: $tableFooterCellBg;
    }

    .p-sortable-column {
        .p-sortable-column-icon {
            color: $tableHeaderCellIconColor;
            margin-left: $inlineSpacing;
        }

        .p-sortable-column-badge {
            border-radius: 50%;
            height: $tableSortableColumnBadgeSize;
            min-width: $tableSortableColumnBadgeSize;
            line-height: $tableSortableColumnBadgeSize;
            color: $highlightTextColor;
            background: $highlightBg;
            margin-left: $inlineSpacing;
        }

        &:not(.p-highlight):hover {
            background-color: $primaryWhiteColor;
            color: $srt-neutral-500;

            .p-sortable-column-icon {
                color: $tableHeaderCellIconHoverColor;
            }
        }

        &.p-highlight {
            background-color: $srt-neutral-100;
            color: $srt-neutral-500;

            .p-sortable-column-icon {
                color: $tableHeaderCellHighlightTextColor;
            }

            &:hover {
                background: $tableHeaderCellHighlightHoverBg;
                color: $tableHeaderCellHighlightTextColor;

                .p-sortable-column-icon {
                    color: $tableHeaderCellHighlightTextColor;
                }
            }
        }
    }

    .p-datatable-tbody {
        >tr {
            background-color: unset;

            &:nth-child(2n) {
                background-color: $srt-neutral-200;
                border-radius: 10px;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
            }

            >td {
                vertical-align: middle;
                padding: 9px 8px;
                color: $srt-neutral-400;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.5;
                height: 48px;
                border: unset;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
                word-break: break-word;
                // white-space: nowrap;

                &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    padding: 9px 12px 9px 22px;
                    color: $srt-neutral-300;
                    font-weight: 500;
                }

                &:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 9px 12px 9px 12px;
                }

                .p-button--button .p-button {
                    background: $srt-white;
                    color: $primaryColor;
                    border-radius: 10px;
                    padding: 6px 12px;
                    font-size: 14px;

                    .pi {
                        font-size: 12px;
                    }
                }

                .p-button--button-large .p-button {
                    font-size: 14px;
                }

                .p-button--link .p-button {
                    background-color: unset;
                    color: $primaryColor;
                    padding: 0;
                    font-weight: 500;
                    font-size: 14px;
                    box-shadow: unset;
                    border: unset;

                    .p-button-label {
                        text-decoration: underline;
                    }
                }

                .action-btn .p-button {
                    padding: 5px 12px;
                    width: auto;
                }

                .p-row-toggler,
                .p-row-editor-init,
                .p-row-editor-save,
                .p-row-editor-cancel {
                    @include action-icon();
                }

                .p-row-editor-save {
                    margin-right: $inlineSpacing;
                }
            }

            &:focus-visible {
                outline: 0.15rem solid $focusOutlineColor;
                outline-offset: -0.15rem;
            }

            &.p-highlight {
                background: $highlightBg;
                color: $highlightTextColor;
            }

            &.p-datatable-dragpoint-top>td {
                box-shadow: inset 0 2px 0 0 $highlightBg;
            }

            &.p-datatable-dragpoint-bottom>td {
                box-shadow: inset 0 -2px 0 0 $highlightBg;
            }
        }
    }

    .p-column-resizer-helper {
        background: $tableResizerHelperBg;
    }

    .p-datatable-scrollable-header,
    .p-datatable-scrollable-footer {
        background: $panelHeaderBg;
    }

    &.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table,
    &.p-datatable-scrollable>.p-datatable-wrapper>.p-scroller-viewport>.p-scroller>.p-datatable-table {

        >.p-datatable-thead,
        >.p-datatable-tfoot {
            background-color: $tableHeaderCellBg;
        }
    }

    .p-datatable-loading-icon {
        font-size: $loadingIconFontSize;
    }

    &.p-datatable-gridlines {
        .p-datatable-header {
            border-width: 1px 1px 0 1px;
        }

        .p-datatable-footer {
            border-width: 0 1px 1px 1px;
        }

        .p-paginator-top {
            border-width: 0 1px 0 1px;
        }

        .p-paginator-bottom {
            border-width: 0 1px 1px 1px;
        }

        .p-datatable-thead {
            >tr {
                >th {
                    border-width: 1px 0 1px 1px;

                    &:last-child {
                        border-width: 1px;
                    }
                }
            }
        }

        .p-datatable-tbody {
            >tr {
                >td {
                    border-width: 1px 0 0 1px;

                    &:last-child {
                        border-width: 1px 1px 0 1px;
                    }
                }

                &:last-child {
                    >td {
                        border-width: 1px 0 1px 1px;

                        &:last-child {
                            border-width: 1px;
                        }
                    }
                }
            }
        }

        .p-datatable-tfoot {
            >tr {
                >td {
                    border-width: 1px 0 1px 1px;

                    &:last-child {
                        border-width: 1px 1px 1px 1px;
                    }
                }
            }
        }

        .p-datatable-thead+.p-datatable-tfoot {
            >tr {
                >td {
                    border-width: 0 0 1px 1px;

                    &:last-child {
                        border-width: 0 1px 1px 1px;
                    }
                }
            }
        }

        &:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody {
            >tr {
                >td {
                    border-width: 0 0 1px 1px;

                    &:last-child {
                        border-width: 0 1px 1px 1px;
                    }
                }
            }
        }

        &:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody {
            >tr {
                &:last-child {
                    >td {
                        border-width: 0 0 0 1px;

                        &:last-child {
                            border-width: 0 1px 0 1px;
                        }
                    }
                }
            }
        }
    }

    &.p-datatable-striped {
        .p-datatable-tbody {
            >tr:nth-child(even) {
                background: $tableBodyRowEvenBg;

                &.p-highlight {
                    background: $highlightBg;
                    color: $highlightTextColor;

                    .p-row-toggler {
                        color: $highlightTextColor;

                        &:hover {
                            color: $highlightTextColor;
                        }
                    }
                }
            }
        }
    }

    &.p-datatable-sm {
        .p-datatable-header {
            @include scaledPadding($tableHeaderPadding, $tableScaleSM);
        }

        .p-datatable-thead>tr>th {
            @include scaledPadding($tableHeaderCellPadding, $tableScaleSM);
        }

        .p-datatable-tbody>tr>td {
            @include scaledPadding($tableBodyCellPadding, $tableScaleSM);
        }

        .p-datatable-tfoot>tr>td {
            @include scaledPadding($tableFooterPadding, $tableScaleSM);
        }

        .p-datatable-footer {
            @include scaledPadding($tableFooterPadding, $tableScaleSM);
        }
    }

    &.p-datatable-lg {
        .p-datatable-header {
            @include scaledPadding($tableHeaderPadding, $tableScaleLG);
        }

        .p-datatable-thead>tr>th {
            @include scaledPadding($tableHeaderCellPadding, $tableScaleLG);
        }

        .p-datatable-tbody>tr>td {
            @include scaledPadding($tableBodyCellPadding, $tableScaleLG);
        }

        .p-datatable-tfoot>tr>td {
            @include scaledPadding($tableFooterPadding, $tableScaleLG);
        }

        .p-datatable-footer {
            @include scaledPadding($tableFooterPadding, $tableScaleLG);
        }
    }
}

.p-profile-table {
    .p-datatable-thead tr {
        border-radius: 10px;
    }

    .p-datatable-thead tr th:first-child {
        border-top-left-radius: 10px;
    }

    .p-datatable-thead tr th:nth-child(2n) {
        width: 290px;
    }

    .p-datatable-tbody>tr:nth-child(2n) {
        background-color: $primaryWhiteColor;
        box-shadow: none;
    }

    .p-datatable-tbody>tr>td {
        padding: 12px;
    }

    .p-datatable-tbody>tr>td .p-file-cta {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.p-vendor-details-table {
    .p-datatable-header {
        .p-inputtext {
            padding-left: 15px;
            color: $primaryBlackColor;
        }
    }
}

.p-nowrap-table {
    .p-datatable-tbody {

        >tr {
            >td {
                .rp-expand-table-detail {
                    white-space: normal;

                    label {
                        font-size: 14px;
                        font-weight: 500;
                        color: #2c63b8;
                    }

                    .form-values {
                        padding: 0;
                        border: none;
                        font-size: 14px;
                        font-weight: 400;
                        color: #000;
                    }
                }

            }
        }
    }
}

.vendor-submit-installation,
.view-application-wrap,
.discom-dashboard .discom-dashboard-card,
.integration-setting,
.view-application-dialog-table {
    .p-datatable {
        .p-datatable-wrapper {
            padding: 2px;
            margin: 0;
            overflow: visible;

            .p-datatable-table {
                table-layout: fixed;

                .p-datatable-thead {
                    tr {
                        box-shadow: 0 0 0 1px rgba(214, 218, 226, 1);
                        border-radius: 10px;

                        th {
                            position: relative;

                            &:after {
                                content: '';
                                position: absolute;
                                border-right: 1px solid #d6dae2;
                                height: 20px;
                                width: 1px;
                                inset: 0 0 0 auto;
                                margin: auto;
                            }

                            &:first-child {
                                border-top-left-radius: 10px;
                            }

                            &:last-child {
                                border-top-right-radius: 10px;

                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .p-datatable-tbody {
                    tr {
                        border-top: 1px solid $srt-secondary;
                        background: unset;
                        box-shadow: unset;
                        border-radius: 0;

                        &:first-child {
                            border-top: 0;
                        }

                        td {
                            font-size: 14px;
                            font-weight: 500;
                            overflow: visible;

                            @media screen and (max-width: 1400px) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.view-application-wrap,
.discom-dashboard .discom-dashboard-card,
.integration-setting {
    .p-datatable {
        .p-datatable-wrapper {
            .p-datatable-table {
                .p-datatable-thead {
                    tr {
                        th {
                            &:first-child {
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

.discom-dashboard {
    .discom-dashboard-card {
        .p-datatable {
            .p-datatable-wrapper {
                .p-datatable-table {
                    .p-datatable-thead {
                        tr {
                            th {
                                text-align: center;
                            }
                        }
                    }

                    .p-datatable-tbody {
                        tr {
                            td {
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .graph-table {
        .p-datatable {
            .p-datatable-wrapper {
                padding: 0;
                // border: 1px solid #015296;
                border-radius: 10px;

                .p-datatable-table {
                    .p-datatable-thead {
                        tr {
                            box-shadow: unset;

                            th {
                                font-size: 11px;
                                font-weight: 600;
                                line-height: 1.2;
                                letter-spacing: 0.04em;
                                text-align: center;
                                color: #464F60;
                                padding: 10px;
                                width: auto;

                                &::after {
                                    display: none;
                                }

                                &:first-child {
                                    color: #464F60;
                                }
                            }
                        }
                    }

                    .p-datatable-tbody {
                        tr {
                            td {
                                padding: 10px;
                                text-align: center;
                                height: auto;
                                text-transform: capitalize;
                            }

                            &:nth-child(2n) {
                                box-shadow: unset;

                                td {
                                    background-color: #F9FAFC;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.common-table-bs {
    .p-datatable {
        margin-top: 10px;

        .p-datatable-wrapper {
            padding: 5px 3px;

            .p-datatable-table {
                .p-datatable-thead {
                    tr {
                        border-radius: 10px;

                        th {
                            padding: 12px 5px;

                            &:first-child {
                                border-top-left-radius: 10px;
                                width: auto;
                                padding: 12px;
                                color: #464f60;
                                font-weight: 600;
                            }

                            &:last-child {
                                border-top-right-radius: 10px;
                                padding: 12px 5px;
                                width: 100px;
                            }
                        }
                    }
                }

                .p-datatable-tbody {
                    tr {
                        td {
                            padding: 12px 5px;

                            &:first-child {
                                padding: 12px;
                                color: #00072B;
                                font-weight: 400;
                            }

                            &:last-child {
                                padding: 12px 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.common-table-first-row {
    .p-datatable {
        .p-datatable-wrapper {
            .p-datatable-table {
                .p-datatable-thead {
                    tr {
                        th {
                            &:first-child {
                                width: 75px;
                            }
                            &:last-child {
                                width: 120px;
                            }
                        }
                    }
                }
                .p-datatable-tbody {
                    tr {
                        td {
                            &:first-child {
                                width: 75px;
                            }
                            &:last-child {
                                width: 120px;
                            }
                        }
                    }
                }
            }
        }
    }

}

.common-table-first-colom-fix {
    .p-datatable {
        .p-datatable-wrapper {
            .p-datatable-table {
                .p-datatable-thead {
                    tr {
                        th {
                            &:first-child {
                                width: 75px;
                            }

                        }
                    }
                }

                .p-datatable-tbody {
                    tr {
                        td {
                            &:first-child {
                                width: 75px;
                            }

                        }
                    }
                }
            }
        }
    }

}

.common-table-only-row-last {
    .p-datatable {
        .p-datatable-wrapper {
            .p-datatable-table {
                .p-datatable-thead {
                    tr {
                        th {
                            &:first-child {
                                width: 220px;
                            }

                            &:last-child {
                                width: 120px;
                            }
                        }
                    }
                }

                .p-datatable-tbody {
                    tr {
                        td {
                            &:first-child {
                                width: 220px;
                            }

                            &:last-child {
                                width: 120px;
                            }
                        }
                    }
                }
            }
        }
    }

}

.vendor-submit-installation .inverted-module {
    .p-datatable {
        .p-datatable-wrapper {
            .p-datatable-table {
                .p-datatable-thead {
                    tr {
                        th {
                            &:first-child {
                                width: 70px;
                            }

                            &:last-child {
                                width: 350px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.p-vendor-change-request-table {
    .p-datatable-table {
        border-spacing: 0 10px;
    }

    .p-datatable-thead tr th:first-child {
        width: 400px;
    }


    .p-datatable-tbody>tr {
        border: 1px solid $primaryGrayColor;
        border-bottom: none;
        border-radius: 8px 8px 0 0;
    }

    .p-datatable-tbody>tr:nth-child(2n) {
        border: 1px solid $primaryGrayColor;
        border-top: none;
        border-radius: 0 0 8px 8px;
    }
}

.custom-table {
    .p-datatable .p-datatable-thead tr th:last-child {
        width: 190px;
    }
}

.add-update-doc {
    .p-datatable .p-datatable-wrapper {
        padding: 3px 5px;
    }
}

.rp-expand-table-detail {
    white-space: normal;

    label {
        font-size: 14px;
        font-weight: 500;
        color: #2c63b8;
    }

    .form-values {
        padding: 0;
        border: none;
        font-size: 14px;
        font-weight: 400;
        color: #000;
    }
}
.rp-table-layout-auto {
    .p-datatable {
        .p-datatable-table {
            table-layout: auto;
            .p-datatable-thead tr th:first-child {
                width: auto;
                }
          }
    }
}

.rp-data-table-sorting{
    position: relative;
    text-wrap-style: balance;
}
.rp-scrollable-table {
    .p-datatable {
        .p-datatable-table {
            table-layout: auto;
            .p-datatable-thead tr th {
                &:first-child {
                    min-width: 80px;
                }
                &:last-child {
                    min-width: 130px;
                }
                min-width: 140px;
                }
          }
    }
}
.rp-mobile-res-vendor-table {
.p-datatable .p-datatable-header {
    @media screen and (max-width: 992px) {
       height: auto;
       overflow-x: auto;
     }
     .rp-filter-header-table {
       justify-content: start !important;
     }
}
}