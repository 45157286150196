.p-card {
    background: var(--bg-color-block);
    color: $panelContentTextColor;
    // box-shadow: $cardShadow;
    border: 1px solid var(--border-input);
    border-radius: $borderRadius;

    .p-card-body {
        padding: $cardBodyPadding;
    }

    .p-card-title {
        font-size: $cardTitleFontSize;
        font-weight: $cardTitleFontWeight;
        margin-bottom: $inlineSpacing;
    }

    .p-card-subtitle {
        font-weight: $cardSubTitleFontWeight;
        margin-bottom: $inlineSpacing;
        color: $cardSubTitleColor;
    }

    .p-card-content {
        padding: 0;
    }

    .p-card-footer {
        padding: $cardFooterPadding;
    }
}

.discom_state {
    .p-card {
        box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.05);
        position: relative;
        overflow: hidden;
        cursor: pointer;

        .p-card-header {
            height: 204px;
            overflow: hidden;
            position: relative;
            background: rgba(1, 82, 150, 0.3);

            &:after {
                content: '';
                background: linear-gradient(180deg, rgba(1, 82, 150, 0) 66.15%, rgba(0, 0, 0, 0.4473) 81.38%, rgba(1, 82, 150, 0.71) 100%);
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
            }

            img {
                object-fit: cover;
                height: 100% !important;
            }
        }

        .p-card-body {
            padding: 0;

            .p-card-title {
                padding: 15px;
                position: absolute;
                width: 100%;
                top: 144px;
                margin: 0;
                color: $srt-white;
                font-weight: 500;
                font-size: 20px;
                line-height: 1.5;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                @media screen and (max-width: 1400px) {
                    padding: 10px;
                    top: 157px;
                    font-size: 18px;
                }

                @media screen and (max-width: 1200px) {
                    top: 160px;
                    font-size: 16px;
                }

                @media screen and (max-width: 768px) {
                    top: 164px;
                    font-size: 14px;
                }
            }

            .p-card-footer {
                padding: 15px 18px;              

                @media screen and (max-width: 1200px) {
                    padding: 10px 18px;
                }

                p {
                    font-size: 16px;
                    font-weight: 500 !important;
                    color:var(--text-color);
                    line-height: 1.5 !important;
                    margin: 4px 0 0 !important;
                    width: 100%;

                    @media screen and (max-width: 1400px) {
                        font-size: 14px;
                    }
                }

                h3 {
                    font-size: 48px;
                    font-weight: 600;
                    color: var(--heading-color);
                    line-height: 1;
                    margin: 0;

                    @media screen and (max-width: 1400px) {
                        font-size: 38px;
                    }

                    @media screen and (max-width: 1200px) {
                        font-size: 32px;
                    }

                    @media screen and (max-width: 992px) {
                        font-size: 28px;
                    }
                }

                .icon-building-line{
                    &::before{
                        color: var(--heading-color);
                    }
                }
            }
        }
    }
}

.discom-state-detail,
.discom_incentive {
    .p-card {
        border: 1px solid $srt-neutral-2700;
        background-color: $srt-neutral-2600;
        min-height: 150px;
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        border-radius: 12px;
        cursor: pointer;

        @media screen and (max-width: 1200px) {
            min-height: 120px;
        }

        .p-card-body {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            padding: 0 24px 0 48px;

            @media screen and (max-width: 1200px) {
                padding: 0 24px 0 30px;
            }

            .p-card-title {
                font-size: 22px;
                font-weight: 500;
                line-height: 1.3;
                color: $srt-black;
                max-width: 345px;
                margin: 0;

                @media screen and (max-width: 1400px) {
                    font-size: 20px;
                }

                @media screen and (max-width: 1200px) {
                    font-size: 18px;
                }

                @media screen and (max-width: 992px) {
                    font-size: 16px;
                }
            }


            .p-card-content {
                .bg-img {
                    position: absolute;
                    bottom: 0;
                    right: 50px;
                    width: 16%;

                    @media screen and (max-width: 1200px) {
                        right: 15px;
                    }

                    @media screen and (max-width: 768px) {
                        right: 35px;
                        width: 13%;
                    }
                }

                .pi {
                    width: 32px;
                    height: 32px;
                    font-size: 20px;
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    color: $srt-neutral-2900;

                    @media screen and (max-width: 1200px) {
                        width: 20px;
                        height: 20px;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

.consumer-root {
    .action-list {
        .p-card {
            background-color: $srt-neutral-2900;
            border-radius: 24px;
            padding: 32px;
            width: 100%;
            height: 100%;

            @media screen and (max-width: 1400px) {
                padding: 28px;
            }

            @media screen and (max-width: 1200px) {
                padding: 24px;
                border-radius: 20px;
            }

            @media screen and (max-width: 992px) {
                padding: 20px;
            }

            @media screen and (max-width: 768px) {
                padding: 16px;
            }

            .p-card-body {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;

                .p-card-title {
                    margin: 0;
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 1.3;
                    color: $srt-white;
                    width: 100%;
                    word-wrap: break-word;

                    @media screen and (max-width: 1400px) {
                        font-size: 28px;
                    }

                    @media screen and (max-width: 1200px) {
                        font-size: 24px;
                    }

                    @media screen and (max-width: 992px) {
                        font-size: 18px;
                    }

                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                    }
                }

                .p-card-content {
                    max-width: 36px;
                    height: 36px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    width: 100%;

                    @media screen and (max-width: 1400px) {
                        max-width: 26px;
                        height: 26px;
                    }

                    @media screen and (max-width: 1200px) {
                        max-width: 20px;
                        height: 20px;
                    }

                    @media screen and (max-width: 992px) {
                        max-width: 18px;
                        height: 18px;
                    }

                    @media screen and (max-width: 768px) {
                        max-width: 16px;
                        height: 16px;
                    }

                    .pi {
                        font-size: 24px;
                        color: $srt-white;

                        @media screen and (max-width: 1400px) {
                            font-size: 18px;
                        }

                        @media screen and (max-width: 1200px) {
                            font-size: 16px;
                        }

                        @media screen and (max-width: 992px) {
                            font-size: 14px;
                        }

                        @media screen and (max-width: 768px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .card-doc {
        .p-card {
            border-radius: 20px;
            padding: 24px 24px 16px 24px;

            @media screen and (max-width: 992px) {
                padding: 16px
            }

            .p-card-header {
                .card-header {
                    display: flex;
                    align-items: center;

                    .icons {
                        width: 32px;
                        height: 32px;
                        border-radius: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 12px;

                        @media screen and (max-width: 1400px) {
                            width: 30px;
                            height: 30px;
                        }

                        .pi {
                            font-size: 20px;
                            color: $srt-white;

                            @media screen and (max-width: 1400px) {
                                font-size: 18px;
                            }
                        }
                    }

                    h2 {
                        font-size: 18px !important;
                        font-weight: 600 !important;
                        line-height: 1.5;
                        color: $srt-black !important;
                        margin: 0 !important;

                        @media screen and (max-width: 1400px) {
                            font-size: 16px !important;
                        }

                        @media screen and (max-width: 1200px) {
                            font-size: 14px !important;
                        }
                    }

                    a {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                        color: $srt-neutral-2900;
                        text-decoration: underline !important;
                        margin: 0 0 0 auto;

                        @media screen and (max-width: 1400px) {
                            font-size: 14px;
                        }

                        @media screen and (max-width: 1200px) {
                            font-size: 12px;
                        }
                    }
                }
            }

            .p-card-body {
                padding: 0;

                img {
                    width: 40%;
                }

                .p-card-content {
                    .list-items {
                        .list-item {
                            border-bottom: 1px solid $srt-neutral-2400;
                            display: flex;
                            justify-content: space-between;
                            cursor: pointer;
                            align-items: center;

                            p {
                                font-size: 16px;
                                font-weight: 500 !important;
                                line-height: 1.3 !important;
                                color: $srt-black;
                                margin: 0 !important;

                                @media screen and (max-width: 1400px) {
                                    font-size: 14px;
                                }
                            }

                            &:last-child {
                                border: unset;
                            }
                        }
                    }
                }
            }
        }
    }

    .help-doc {
        .p-card {
            background-color: $srt-neutral-3300;
            border: 1px solid $srt-neutral-2400;
            height: 100%;

            .p-card-header {
                .card-header {
                    .help-icon {
                        background-color: $srt-neutral-3600;
                    }
                }
            }

            .p-card-body {
                margin-top: 14px;

                .p-card-content {
                    background: $srt-white;
                    border: 1px solid $srt-neutral-2400;
                    border-radius: 8px;
                    padding: 8px 16px;

                    .list-items {
                        .list-item {
                            padding-bottom: 5px;
                            margin-bottom: 5px;


                            &:last-child {
                                padding-bottom: unset;
                                margin-bottom: unset;
                            }
                        }
                    }
                }
            }
        }
    }

    .notification {
        .p-card {
            background-color: $srt-neutral-3400;
            border: 1px solid $srt-neutral-3500;
            height: 100%;

            .p-card-header {
                .card-header {
                    .notify-icon {
                        background-color: $srt-neutral-3500;
                        padding: 8px;
                    }
                }
            }

            .p-card-body {
                margin-top: 10px;

                .p-card-content {
                    .list-items {
                        .list-item {
                            padding: 12px 0;

                            a {
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 1.3;
                                color: #3C8EE3;
                                display: block;
                                text-align: center;
                                text-decoration: underline;
                            }

                            img {
                                width: 134px;
                                margin: 0 auto 10px !important;
                                display: block;
                            }

                            span {
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 1.3;
                                color: $srt-neutral-2300;
                                margin-top: 4px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.app-journey {
    .p-card {
        background-color: $srt-neutral-3300;
        border: 1px solid $srt-neutral-2400;
        padding: 24px;
        border-radius: 20px;

        @media screen and (max-width: 1200px) {
            padding: 20px
        }

        @media screen and (max-width: 992px) {
            padding: 16px
        }

        .p-card-header {
            .card-header {
                display: flex;
                align-items: center;

                .icons {
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 12px;
                    background-color: $srt-neutral-3600;
                    padding: 4px;

                    @media screen and (max-width: 1400px) {
                        width: 30px;
                        height: 30px;
                    }

                    .pi {
                        font-size: 20px;
                        color: $srt-white;

                        @media screen and (max-width: 1400px) {
                            font-size: 18px;
                        }
                    }
                }

                h2 {
                    font-size: 18px !important;
                    font-weight: 600 !important;
                    line-height: 1.5;
                    color: $srt-black !important;
                    margin: 0 !important;

                    @media screen and (max-width: 1400px) {
                        font-size: 16px !important;
                    }

                    @media screen and (max-width: 1200px) {
                        font-size: 14px !important;
                    }
                }
            }
        }

        .p-card-body {
            padding: 0;
        }
    }
}

.actions-app-card {
    .p-card .p-card-body {
        padding: unset;
    }

    .p-card {
        margin: 12px;
        width: 320px;
    }

    .p-card .p-card-content {
        padding: 14px;
        min-height: 200px;
    }

    .p-card .p-card-footer {
        border: 1px solid #E2E2EA;
        background: #FAFAFA;
        display: flex;
        justify-content: center;
        border-radius: 0px 0px 10px 10px;
    }

    .p-card .p-card-footer {
        padding: 0px;
    }
}

.download-btn-o {
    .p-card .p-card-body {
        padding: 17px 32px 17px 32px;
        min-width: 206px;
    }
}

.feasibility-tab {
    .app-journey .p-card {
        margin: 5px !important;
    }
}

.discom-dashboard .common-discom-filter,
.gb-dashboard .gb-dashboard-filter {
    .p-card {
        .p-card-body {
            padding: 12px;

            .p-card-content {

                .p-dropdown {
                    align-items: center;
                    background-color: transparent;
                    padding: 7px 10px;
                    width: 100%;

                    .p-dropdown-label {
                        padding: 0;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 1.2;
                        color: #000;
                        padding-right: 13px;

                        &.p-placeholder {
                            color: #667085;
                        }
                    }

                    .p-dropdown-trigger {
                        width: 19px;
                        height: 19px;
                        color: #667085;

                        .p-icon {
                            width: 12px;
                        }
                    }

                    .p-dropdown-panel .p-dropdown-items {

                        .p-dropdown-item,
                        .p-dropdown-empty-message {
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .p-icon-wrapper {
                                order: 2;
                            }

                            span {
                                order: 1;
                            }
                        }
                    }

                    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
                        font-size: 12px;
                        border: unset;
                    }
                }

                .p-calendar {
                    display: flex;

                    .p-inputtext {
                        padding: 7px 10px;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 1.2;
                        color: #000;
                        height: 100%;
                        width: 100%;
                        box-shadow: unset;

                        &::placeholder {
                            font-size: 12px;
                            color: #667085;
                        }

                        &:hover {
                            border-color: #E2E2EA;
                        }
                    }
                }

                .p-button {
                    padding: 6px 20px;
                    border-radius: 4px;

                    .p-button-label {
                        font-size: 14px;
                    }
                }

                .p-selectbutton {
                    border: 1px solid #E2E2EA;
                    border-radius: 10px;
                    display: flex;

                    .p-button {
                        border: unset;
                        border-radius: 10px;
                        padding: 10px 0 9px;
                        width: 50%;
                        background-color: #fff !important;

                        .p-button-label {
                            color: #015296;
                        }

                        &.p-highlight {
                            background-color: #015296 !important;

                            .p-button-label {
                                color: #fff;
                            }
                        }
                    }
                }

                .error {
                    font-size: 12px;
                    font-weight: 500;
                    color: red;
                    margin-top: 6px;
                    margin-left: 6px;
                }
            }
        }
    }
}

.discom-dashboard .discom-dashboard-card,
.gb-dashboard .gb-dashboard-card {
    .p-card {
        margin-bottom: 10px;
        overflow: hidden;

        .p-card-body {
            padding: 0;

            .p-card-content {
                .amount {
                    position: relative;
                    min-height: 76px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;


                    h4,
                    h5,
                    h6 {
                        font-weight: 500;
                        line-height: 1.2;
                        text-align: center;
                        color: #000;
                    }

                    h4,
                    h6 {
                        font-size: 11px;
                    }

                    h5 {
                        font-size: 19px;
                    }
                }

                .status {
                    background-color: #C9DAE9;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 1.2;
                    text-align: center;
                    color: #015296;
                    padding: 3px 0;
                }
            }
        }
    }
}

.discom-dashboard {
    .graphs {
        margin-top: 20px;
        margin-bottom: 20px;
        display: block;

        .p-card {
            .p-card-body {
                padding: 20px;
            }
        }
    }
}
