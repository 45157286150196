p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
    @include invalid-input();
}

.p-password-panel {
    padding: $panelContentPadding;
    background: $panelContentBg;
    color: $panelContentTextColor;
    border: $overlayContentBorder;
    box-shadow: $inputOverlayShadow;
    border-radius: $borderRadius;

    .p-password-meter {
        margin-bottom: $inlineSpacing;
        background: $passwordMeterBg;

        .p-password-strength {
            &.weak {
                background: $passwordWeakBg;
            }

            &.medium {
                background: $passwordMediumBg;
            }

            &.strong {
                background: $passwordStrongBg;
            }
        }
    }
}

p-password.p-password-clearable {
    .p-password-input {
        padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
    }
    .p-password-clear-icon {
        color: $inputIconColor;
        right: nth($inputPadding, 2);
    }
}

p-password.p-password-clearable.p-password-mask{
    .p-password-input {
        padding-right: nth($inputPadding, 2) * 3 + $primeIconFontSize * 2;
    }

    .p-password-clear-icon {
        color: $inputIconColor;
        right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
    }
}

.password-input{
    width: 100%;
    border-radius: 10px;
    .p-password-input{
        width: 100%;
    }
}

.p-govt-build-dialog {
    .p-password-input{
        width: 100%;
    }
}
.rp-password-custom {
    position: relative;
    .pi{
        position: absolute;
        top: 50%;
        cursor: pointer;
        right: 15px;
        z-index: 9;
        margin-top: -0.5rem;
    }
}