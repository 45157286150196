.p-fieldset {
    border: $panelContentBorder;
    background: $panelContentBg;
    color: $panelContentTextColor;
    border-radius: $borderRadius;

    .p-fieldset-legend {
        padding: $panelHeaderPadding;
        border: $panelHeaderBorder;
        color: $panelHeaderTextColor;
        background: $panelHeaderBg;
        font-weight: $panelHeaderFontWeight;
        border-radius: $borderRadius;
    }

    &.p-fieldset-toggleable {
        .p-fieldset-legend {
            padding: 0;
            transition: $actionIconTransition;

            a {
                padding: $panelHeaderPadding;
                color: $panelHeaderTextColor;
                border-radius: $borderRadius;
                transition: $listItemTransition;

                .p-fieldset-toggler {
                    margin-right: $inlineSpacing;
                }

                &:focus-visible {
                    @include focused();
                }
            }

            &:hover {
                background: $panelHeaderHoverBg;
                border-color: $panelHeaderHoverBorderColor;
                color: $panelHeaderTextHoverColor;
            }
        }
    }

    .p-fieldset-content {
        padding: $panelContentPadding;
    }
}

.vendor-submit-installation {
    .p-fieldset {
        margin: 0;
        padding: 0;
        border: 0;

        .p-fieldset-legend {
            background-color: $srt-neutral-1100;
            border-radius: 10px;
            padding: 10px 0;
            display: block;
            height: auto;
            width: 100%;
            text-align: left;
            position: relative;

            &:after {
                content: '';
                border-top: 1px solid $primaryColor;
                width: calc(100% - 10px);
                position: absolute;
                inset: 0;
                margin: auto;
                height: 1px;
                right: 10px;
            }

            .p-fieldset-legend-text {
                font-size: 14px;
                color: $primaryColor;
                font-weight: 600;
                line-height: 1.5;
                display: block;
                width: fit-content;
                background-color: $srt-neutral-1100;
                z-index: 1;
                position: relative;
                padding: 0 10px;

                @media screen and (max-width: 1400px) {
                    font-size: 12px;
                }
            }
        }

        .p-fieldset-content {
            padding: 0;
        }
    }
}