.p-fileupload {
    .p-fileupload-buttonbar {
        background: $panelHeaderBg;
        padding: $panelHeaderPadding;
        border: $panelHeaderBorder;
        color: $panelHeaderTextColor;
        border-bottom: 0 none;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;

        .p-button {
            margin-right: $inlineSpacing;

            &.p-fileupload-choose.p-focus {
                @include focused();
            }
        }
    }

    .p-fileupload-content {
        background: $panelContentBg;
        padding: $fileUploadContentPadding;
        border: $panelContentBorder;
        color: $panelContentTextColor;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;

        &.p-fileupload-highlight {
            border-color: $fileUploadContentHoverBorder;
            border-style: dashed;
            background-color: $highlightBg;
        }
    }

    .p-progressbar {
        height: $fileUploadProgressBarHeight;
    }

    .p-fileupload-row {
        > div {
            padding: $tableBodyCellPadding;
        }
    }

    &.p-fileupload-advanced {
        .p-message {
            margin-top: 0;
        }
    }
}

.p-fileupload-choose {
    &:not(.p-disabled):hover {
        background: $buttonHoverBg;
        color: $buttonTextHoverColor;
        border-color: $buttonHoverBorderColor;
    }

    &:not(.p-disabled):active {
        background: $buttonActiveBg;
        color: $buttonTextActiveColor;
        border-color: $buttonActiveBorderColor;
    }
}

.submit-detail {
    .p-fileupload {
        display: flex;
        flex-direction: column;
        align-items: center;

        .p-fileupload-buttonbar {
            padding: 0;
            border: unset;
            width: fit-content;
            background: unset;
            border-radius: unset;

            .p-button {
                margin: 0;
                padding: 0;
                background: unset;
                border: unset;
                border-radius: unset;
            }
        }
        .p-fileupload-content {
            padding: 0;
            border: 0;
            background: unset;
            border-radius: unset;

            .p-progressbar {
                display: none;
            }

            .p-fileupload-files {
                display: none;
            }

            ul {
                padding: 15px 0 !important;
                display: flex;
                gap: 10px;
                flex-wrap: nowrap;
            }

            li {
                display: flex;
                align-items: center;
                gap: 15px;
                justify-content: flex-end;
                border: 1px solid $srt-neutral-2400;
                border-radius: 12px;
                padding: 10px 20px;

                p {
                    margin: 0 auto 0 0;
                }

                .p-button {
                    padding: 0;
                    border-radius: 0;
                    background-color: unset;
                    border: unset;
                    width: fit-content;

                    .pi {
                        color: $srt-black;
                    }

                    .pi-trash {
                        color: red;
                    }
                }
            }
        }
    }
}

.approve-ins{
    .p-fileupload{
        border: 1px solid #d5d5e0;
        display: flex;
        justify-content: flex-end;
        border-radius:12px;
    }
}
