.p-radiobutton {
    width: $radiobuttonWidth;
    height: $radiobuttonHeight;

    .p-radiobutton-box {
        border: $radiobuttonBorder;
        background: $inputBg;
        width: $radiobuttonWidth;
        height: $radiobuttonHeight;
        color: $textColor;
        border-radius: 50%;
        transition: $formElementTransition;
        outline-color: transparent;
        .p-radiobutton-icon {
            width: $radiobuttonIconSize;
            height: $radiobuttonIconSize;
            transition-duration: $transitionDuration;
            background-color: $radiobuttonIconActiveColor;
        }

        &.p-highlight {
            border-color: $radiobuttonActiveBorderColor;
            background: $radiobuttonActiveBg;           
        }
    }

    &.p-variant-filled {
        .p-radiobutton-box {
            background-color: $inputFilledBg;
        }
    }
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    @include invalid-input();
}

.p-input-filled {
    .p-radiobutton {
        .p-radiobutton-box {
            background-color: $inputFilledBg;
            
        }
    }
}

.p-radiobutton-label {
    margin-left: $inlineSpacing;
}

@if ($highlightBg == $radiobuttonActiveBg) {
    .p-highlight {
        .p-radiobutton {
            .p-radiobutton-box {
                border-color: $radiobuttonIconActiveColor;
            }
        }
    }
}

.approve-feasibility-popup {
    .p-radiobutton {
        width: 16px;
        height: 16px;

        .p-radiobutton-box {
            width: 16px;
            height: 16px;
            border: 1px solid #015296;
        }
    }
}

.reqoption {
    .p-radiobutton {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
    }
}