.p-tabview {
    .p-tabview-nav-content {
        scroll-padding-inline: $buttonIconOnlyWidth;

        @media screen and (max-width:565px) {
            text-wrap-mode: nowrap;
        }
    }

    .p-tabview-nav {
        background: var(--bg-color);
        border: $tabviewNavBorder;
        border-width: $tabviewNavBorderWidth;
        display: flex;
        flex-direction: row;
        gap: 10px;

        li {
            margin-right: $tabviewHeaderSpacing;

            .p-tabview-nav-link {
                border-bottom: 3px solid transparent;
                background: var(--bg-color);
                color: var(--text-color);
                padding: 10px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                transition: $listItemTransition;

                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                    padding: 1rem;
                }

                span{
                    &::before{
                        color: var(--heading-color) !important; 
                    }
                }


                &:not(.p-disabled):focus-visible {
                    @include focused-inset();
                }
            }

            &:not(.p-highlight):not(.p-disabled):hover {
                .p-tabview-nav-link {
                    background: var(--bg-active-color);
                    color: var(--heading-color);
                }
            }

            &.p-highlight {
                .p-tabview-nav-link {
                    background: var(--bg-active-color);
                    border-bottom: 3px solid var(--heading-color);
                    color: var(--heading-color);
                }
            }
        }
    }

    .p-tabview-left-icon {
        margin-right: $inlineSpacing;
    }

    .p-tabview-right-icon {
        margin-left: $inlineSpacing;
    }

    .p-tabview-close {
        margin-left: $inlineSpacing;
    }

    .p-tabview-nav-btn.p-link {
        background: $tabviewHeaderActiveBg;
        color: $tabviewHeaderTextActiveColor;
        width: $buttonIconOnlyWidth;
        box-shadow: $raisedButtonShadow;
        border-radius: 0;

        &:focus-visible {
            @include focused-inset();
        }
    }

    .p-tabview-panels {
        background: var(--bg-color);

        h3 {
            font-size: 24px !important;
            font-weight: 700 !important;
            line-height: 36px !important;
            margin-bottom: 1rem !important;

            @media only screen and (max-width: 767px) {
                font-size: 18px !important;
                line-height: 26px !important;
            }
        }

    }
}

.wrap-vendor-process {
    .p-tabview {
        display: flex;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
        }

        .p-tabview-nav {
            display: flex;
            flex-direction: column;
            gap: 5px;

            @media only screen and (max-width: 767px) {
                flex-direction: row;
            }
        }

        li {
            .p-tabview-nav-link {
                padding: 20px 10px;
                font-size: 20px;
                font-weight: 700;
                line-height: 36px;
                border-bottom: none !important;

                @media only screen and (max-width: 767px) {
                    padding: 1rem;
                    font-size: 20px;
                }
            }
        }

        .p-tabview-panels {
            background: var(--bg-active-color);
            padding: 1rem;

            p {
                font-size: 20px;
            }
        }
    }
}

.findVendorcontainer {
    .p-tabview .p-tabview-nav li {
        width: 20%;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
        a {
            .p-tabview-title {
                width: 100%;
                text-align: center;
            }
        }
    }
}

.consumer-change-request-custom-table .p-datatable-thead>tr>th {
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
    background-color: #015296 !important;
    color: white !important;
    text-transform: none;
    padding: 10px;
    font-size: 14px;
    text-align: center;
    font-weight: 400;


    @media screen and (max-width: 768px) {
        font-size: 13px;
    }
}

.consumer-change-request-custom-table .p-datatable-tbody>tr {
    background-color: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
}

.consumer-change-request-custom-table .p-datatable-tbody>tr>td {
    padding: 10px;
    border: 1px solid #e5e5e5;
    text-align: center;
}

.consumer-change-request-custom-table .status-column-1 {
    color: #1FA822 !important;
}

.consumer-change-request-custom-table .status-column-2 {
    color: #ff9800 !important;
}

.consumer-change-request-custom-table .status-column-3 {
    color: #C73037 !important;
}

.srt-tab {
    .p-tabview {
        .p-tabview-nav-container {
            margin-bottom: 8px;

            .p-tabview-nav-content {
                .p-tabview-nav {
                    display: flex;
                    flex-direction: row;
                    background-color: unset;
                    gap: 0;

                    .p-tabview-nav-link {
                        padding: 10px;
                        background-color: transparent !important;
                        font-weight: 600;
                        font-size: 14px;
                        text-transform: capitalize;
                        color: $srt-neutral-800;
                        box-shadow: unset;
                        border-bottom: 1px solid transparent;

                        .p-badge {
                            border: 1px solid $srt-neutral-1100;
                            background-color: unset;
                            border-radius: 9px;
                            padding: 0 7px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 1.4;
                            height: 19px;
                            vertical-align: middle;
                            display: flex;
                            align-items: center;
                            color: $srt-neutral-800;
                        }

                        .status-icon {
                            filter: brightness(0) saturate(100%) invert(67%) sepia(6%) saturate(0%) hue-rotate(174deg) brightness(86%) contrast(98%);
                        }
                    }

                    .p-highlight {
                        .p-tabview-nav-link {
                            color: var(--heading-color);
                            border-bottom: 1px solid var(--heading-color);

                            .p-badge {
                                color: var(--heading-color);
                            }

                            .status-icon {
                                filter: brightness(0) saturate(100%) invert(17%) sepia(78%) saturate(2442%) hue-rotate(192deg) brightness(101%) contrast(99%);
                            }
                        }
                    }
                }
            }
        }

        .p-tabview-panels {
            padding: unset;
            background-color: unset;
        }
    }
}

.inspection-tabs {
    .p-tabview {
        .p-tabview-nav-container {
            .p-tabview-nav-content {
                .p-tabview-nav {
                    .p-tabview-nav-link {
                        font-size: 14px;
                    }
                }
            }
        }

        .p-tabview-panels {
            background-color: #F9F9F9;
            border-radius: 10px;
            padding: 15px;

            .inspection-heading-wrap {
                border-bottom: 1px solid #015296;
                min-height: 37px;
                display: flex;
                align-items: center;

                h2 {
                    font-size: 18px !important;
                    font-weight: 500 !important;
                    line-height: 1.4;
                    color: #015296 !important;
                    margin: 0 !important;
                }
            }


            h3 {
                font-size: 14px !important;
                font-weight: 500 !important;
                line-height: 1.2 !important;
                letter-spacing: 0.0015em;
                color: #959494 !important;
                margin: 0 !important;
            }

            .p-radiobutton {
                width: 16px;
                height: 16px;

                .p-radiobutton-box {
                    width: 16px;
                    height: 16px;
                    border: 1px solid #015296;
                }
            }

            .checklist-wrap {
                border: 1px solid #EAECF0;
                background-color: #FFFFFF;
                border-radius: 8px;
                padding: 10px 16px;

                .checklist-name {
                    flex: 1;
                    
                    span {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 1.2;
                        color: #667085;
                    }
                }

                .p-inspection-view.p-disabled {
                    .p-button-link {
                        filter: brightness(0) saturate(100%) invert(72%) sepia(4%) saturate(69%) hue-rotate(314deg) brightness(84%) contrast(75%);
                    }
                }

                .p-button-link {
                    gap: 12px;

                    .p-button-label {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.2;
                        color: #015296;
                    }
                }

                .checklist-actions {
                    label {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.2;
                        letter-spacing: 0.04em;
                        color: #000;
                        margin: 0;
                    }
                }
            }

            .field-checkbox {
                margin: 0 !important;
                align-items: center;
                border: 1px solid #D0D5DD;
                border-radius: 5px;
                padding: 10px 18px;

                label {
                    margin: 0;
                }
            }
        }
    }
}

.financing-option {
    .p-tabview {
        .p-tabview-nav-container {
            .p-tabview-nav-content {
                border-bottom: 1px solid #E2E2EA;
                margin-bottom: 1rem;

                .p-tabview-nav {
                    background-color: unset;
                    gap: 12px;

                    li {

                        .p-tabview-nav-link {
                            border-bottom: 4px solid transparent;
                            background-color: unset;
                            padding: 10px 20px;

                            .p-tabview-title {
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 1.5;
                                color: #000;
                            }
                        }
                    }

                    li.p-highlight {
                        .p-tabview-nav-link {
                            border-bottom: 4px solid #319DD6;
                            background-color: unset;

                            .p-tabview-title {
                                color: #319DD6;
                            }
                        }
                    }
                }
            }
        }

        .p-tabview-panels {
            background-color: unset;
        }
    }
}

.discom-dashboard {
    .p-tabview {
        .p-tabview-nav-container {
            margin-bottom: 15px;

            .p-tabview-nav-content {
                .p-tabview-nav {
                    background-color: transparent;
                    gap: 0;

                    li {
                        .p-tabview-nav-link {
                            background-color: transparent;
                            color: #959494;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 1.4;
                            border-bottom: 1px solid transparent;
                            gap: 10px;

                            .tab-img {
                                filter: brightness(0) saturate(100%) invert(58%) sepia(5%) saturate(9%) hue-rotate(33deg) brightness(99%) contrast(93%);
                            }

                            .p-tabview-title {
                                line-height: unset;
                            }
                        }
                    }

                    li.p-highlight {
                        .p-tabview-nav-link {
                            color: #015296;
                            border-color: #015296;

                            .tab-img {
                                filter: brightness(0) saturate(100%) invert(14%) sepia(62%) saturate(5403%) hue-rotate(196deg) brightness(92%) contrast(99%);
                            }
                        }
                    }
                }
            }
        }

        .p-tabview-panels {
            background-color: transparent;
        }
    }
}