.p-menu {
    padding: $verticalMenuPadding;
    background: $menuBg;
    color: $menuTextColor;
    border: $menuBorder;
    border-radius: $borderRadius;
    width: $menuWidth;

    .p-menuitem {
        @include menuitem();
    }

    &.p-menu-overlay {
        background: $overlayMenuBg;
        box-shadow: $overlayMenuShadow;
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid $srt-neutral-1100;
        width: auto;
        min-width: 7rem;
    }

    .p-submenu-header {
        margin: $submenuHeaderMargin;
        padding: $submenuHeaderPadding;
        color: $submenuHeaderTextColor;
        background: $submenuHeaderBg;
        font-weight: $submenuHeaderFontWeight;
        border-top-right-radius: $submenuHeaderBorderRadius;
        border-top-left-radius: $submenuHeaderBorderRadius;
        display: none;
    }

    .p-menuitem-separator {
        border-top: $divider;
        margin: $menuSeparatorMargin;
    }

    .p-menuitem-badge {
        @include menuitem-badge();
    }
}

/* Website Menu Bar CSS */
.p-web-menu {
    .p-menuitem>.p-menuitem-content {
        padding: 0 !important;
    }
}

.srt-table {
    .p-menu .p-menu-list {
        .p-menuitem {
            &:nth-child(2) {
                .p-menuitem-content .pi {
                    color: green;
                    width: 20px;
                }
            }
            &:nth-child(3) {
                .p-menuitem-content .pi {
                    color: yellow;
                    width: 20px;
                }
            }
            &:nth-child(4) {
                .p-menuitem-content .pi {
                    color: red;
                    width: 20px;
                }
            }
        }
    }
}