.p-dropdown {
    background: $primaryWhiteColor;
    border: 1px solid $primaryGrayColor;
    transition: $formElementTransition;
    border-radius: $borderRadius;
    padding: 0.25rem;
    position: relative;

    &.p-dropdown-clearable {
        .p-dropdown-label {
            padding-right: nth($inputPadding, 2) + $primeIconFontSize;
            color: $primaryBlackColor;

            &.p-inputtext {
                color: $primaryBlackColor;
            }
        }
    }

    .p-dropdown-label {
        background: transparent;
        border: 0 none;
        color: $primaryBlackColor;
        word-break: break-word;


        &.p-placeholder {
            color: $inputPlaceholderTextColor;
        }

        &:focus,
        &:enabled:focus {
            outline: 0 none;
            box-shadow: none;
        }
    }

    .p-dropdown-trigger {
        background: transparent;
        color: $primaryBlackColor;
        width: $inputGroupAddOnMinWidth;
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }

    .p-dropdown-clear-icon {
        color: $inputIconColor;
        right: $inputGroupAddOnMinWidth;
    }

    &.p-invalid.p-component {
        @include invalid-input();
    }

    &.p-variant-filled {
        background-color: $inputFilledBg;

        &:enabled:hover {
            background-color: $inputFilledHoverBg;
        }

        &:enabled:focus {
            background-color: $inputFilledFocusBg;
        }
    }
}

.p-dropdown-panel {
    background: $primaryWhiteColor;
    color: $inputListTextColor;
    border: 1px solid $primaryColor;
    border-radius: $borderRadius;
    font-size: .875rem;
    box-shadow: $inputOverlayShadow;
    overflow: hidden;

    .p-dropdown-header {
        padding: $inputListHeaderPadding;
        border-bottom: $inputListHeaderBorder;
        color: $inputListHeaderTextColor;
        background: $inputOverlayHeaderBg;
        margin: $inputListHeaderMargin;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;

        .p-dropdown-filter {
            padding-right: 2rem;
            margin-right: -1 * (nth($inputPadding, 2) + $primeIconFontSize);
        }

        .p-dropdown-filter-container {
            border: 1px solid $primaryBlackColor;
            border-radius: 10px;
        }

        .p-dropdown-filter-icon {
            right: nth($inputPadding, 2);
            color: $inputIconColor;
        }
    }

    .p-dropdown-items {
        padding: $inputListPadding;

        .p-dropdown-item {
            margin: $inputListItemMargin;
            padding: $inputListItemPadding;
            border: $inputListItemBorder;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
            transition: $listItemTransition;
            border-radius: $inputListItemBorderRadius;
            white-space: break-spaces;

            &:first-child {
                margin-top: 0;
            }

            &.p-highlight {
                color: $primaryColor;
                background: $primaryWhiteColor;

                &.p-focus {
                    background: $primaryWhiteColor;
                }
            }

            &:not(.p-highlight):not(.p-disabled) {
                &.p-focus {
                    color: $inputListItemTextFocusColor;
                    background: $inputListItemFocusBg;
                }

                &:hover {
                    color: $inputListItemTextHoverColor;
                    background: $inputListItemHoverBg;
                }
            }
        }

        .p-dropdown-item-group {
            margin: $submenuHeaderMargin;
            padding: $submenuHeaderPadding;
            color: $submenuHeaderTextColor;
            background: $submenuHeaderBg;
            font-weight: $submenuHeaderFontWeight;
        }

        .p-dropdown-empty-message {
            padding: $inputListItemPadding;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
        }
    }
}

.p-input-filled {
    .p-dropdown {
        background: $inputFilledBg;

        &:not(.p-disabled):hover {
            background-color: $inputFilledHoverBg;
        }

        &:not(.p-disabled).p-focus {
            background-color: $inputFilledFocusBg;

            .p-inputtext {
                background-color: transparent;
            }
        }
    }
}

p-dropdown.ng-dirty.ng-invalid>.p-dropdown {
    @include invalid-input();
}

.discom-state-detail {
    .discom-state-header {
        .state_list {
            .p-dropdown {
                padding: 12px;
                border: 1px solid $srt-neutral-2400;
                border-radius: 8px;
                width: 290px;

                @media screen and (max-width: 1200px) {
                    padding: 10px;
                    width: 260px;
                }

                .p-dropdown-label {
                    padding: 0;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.5;
                    color: $srt-black;
                    margin-right: 24px;

                    @media screen and (max-width: 1200px) {
                        font-size: 12px;
                    }
                }

                .p-dropdown-trigger {
                    width: 20px;
                    height: 20px;
                }

                .p-overlay {
                    min-width: 270px !important;
                    left: unset !important;
                    right: 0;

                    @media screen and (max-width: 1200px) {
                        min-width: 200px !important;
                    }
                }
            }

            .p-dropdown-item {
                font-size: 14px;
                line-height: 1.2;
                white-space: break-spaces;

                @media screen and (max-width: 1200px) {
                    font-size: 12px;
                }
            }
        }
    }
}

.discom_incentive {
    .p-dropdown {
        width: 100%;
        padding: 12px;
        border: 1px solid $srt-neutral-2400;
        border-radius: 8px;

        @media screen and (max-width: 1400px) {
            padding: 10px 12px;
        }

        .p-dropdown-label {
            padding: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            color: $srt-black;

            @media screen and (max-width: 1400px) {
                font-size: 12px;
            }
        }

        .p-dropdown-label.p-inputtext.p-placeholder {
            color: $srt-neutral-2300;
        }
    }

    .p-dropdown-item {
        font-size: 14px;
        line-height: 1.2;
        white-space: break-spaces;

        @media screen and (max-width: 1400px) {
            font-size: 12px;
        }
    }

    .p-togglebutton.p-button,
    .p-togglebutton.p-button.p-highlight {
        background: $srt-white !important;
        border: 1px solid $srt-neutral-2400 !important;
        border-radius: 8px;
        padding: 16px;

        .p-button-label {
            font-size: 16px;
            font-weight: 400;
            color: $srt-black;
            line-height: 1.5;
            padding: 0;

            @media screen and (max-width: 1400px) {
                font-size: 14px;
            }
        }

        .p-button-icon {
            color: $srt-black !important;
            order: 2;
        }
    }
}

.complete-profile-dialog {
    .p-dropdown {
        .p-dropdown-label {
            &.p-placeholder {
                color: $primaryBlackColor !important;
            }
        }
    }
}

.complete-profile-dialog,
.consumer-profile,
.consumer-app-popup,
.bank-d-labels,
.submit-detail {
    .disabled {
        .p-dropdown {
            background-color: #eee;
        }
    }

    .p-dropdown {
        padding: 12px;
        border: 1px solid;
        border-radius: 8px;
        width: 100%;
        border-color: $srt-neutral-2400 !important;

        @media screen and (max-width: 1400px) {
            padding: 10px;
        }

        .p-dropdown-label {
            padding: 0 25px 0 0;
            border: 0;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;
            color: $srt-black;

            @media screen and (max-width: 1400px) {
                font-size: 12px;
            }

            &.p-placeholder {
                color: $srt-neutral-2300;
            }
        }

        .p-dropdown-item,
        .p-dropdown-empty-message {
            font-size: 12px;
        }

        .p-dropdown-clear-icon {
            right: 3rem;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        .p-icon {
            width: 12px;
            height: 12px;
        }
    }
}

.knowledge-dropdown {
    width: 300px;

    @media only screen and (max-width: 767px) {
        width: 100%;
    }

    .p-dropdown-clear-icon {
        right: 3rem;
    }
}

.product-list-add-data-div,
.select-discom,
.p-govt-build-dialog,
.vendor-submit-installation {
    .p-dropdown-clear-icon {
        right: 3rem;
    }
}

.p-vendor-filters {
    // .p-dropdown-trigger {
    //     width: 0;
    // }

    .p-vendor-value {
        width: 90%;
        overflow: hidden;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.rp-autocomplete-full {
    .p-filter-panel {
        width: 100%;
    }
}

.p-filter-panel {
    width: 260px;

    @media only screen and (max-width: 1200px) {
        width: 70%;
    }

    @media only screen and (max-width: 1200px) {
        width: 100%;
    }

    .p-filter-panel-value {
        white-space: break-spaces;
    }
}

.user-profile {
    .p-dropdown {
        width: fit-content;
        background: $primaryColor;
        border-radius: 38px;
        padding: 6px 24px 6px;
        min-height: 44px;
        align-items: center;

        @media screen and (max-width: 1200px) {
            min-height: 40px;
        }

        .p-dropdown-label.p-placeholder,
        .p-dropdown-label {
            color: $srt-white;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            padding: 0;
            width: 100px;

            @media screen and (max-width: 1400px) {
                font-size: 14px;
            }

            @media screen and (max-width: 1200px) {
                font-size: 12px;
            }
        }

        .p-dropdown-trigger {
            color: $srt-white;
        }
    }

}

.new-app-form {
    .p-dropdown {
        width: 100%;
    }

    .p-dropdown-clear-icon {
        color: #000 !important;
        right: 3rem !important;
    }
}

.p-vendor-details-dropdown {
    padding: 0.3rem 0;
}

.vendor-submit-installation {
    .p-dropdown {
        padding: 10px 16px;
        width: 100%;

        .p-dropdown-label {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;
            padding: 0;
            color: $srt-neutral-2100-1;

            @media screen and (max-width: 1400px) {
                font-size: 12px;
            }

            &.p-placeholder {
                color: $srt-neutral-800;
            }
        }

        .p-icon {
            width: 14px;
            height: 14px;

            @media screen and (max-width: 1400px) {
                width: 12px;
                height: 12px;
            }
        }

        .p-dropdown-panel .p-dropdown-items {

            .p-dropdown-item,
            .p-dropdown-empty-message {
                font-size: 14px;
                text-align: left;

                @media screen and (max-width: 1200px) {
                    font-size: 12px;
                }
            }
        }
    }
}

.login-dropdown {
    padding-left: 32px;
    width: 145px;
    background-color: $primaryColor;
    border: 1px solid $primaryColor;
    padding-right: 0;

    .p-dropdown-label {
        color: $primaryWhiteColor !important;
        line-height: 0.8;
        font-size: 16px;
        padding-right: 0;
    }

    .p-dropdown-trigger {
        color: $primaryWhiteColor;
    }

    &:before {
        position: absolute;
        left: 16px;
        top: 13px;
        content: "\e970";
        font-family: primeicons;
        color: $primaryWhiteColor;
        font-size: 1rem;
    }
}

.login-dropdown-panel {
    width: 325px;
    min-width: auto;

    .p-dropdown-item {
        white-space: normal;
        line-height: 20px;
    }
}

.add-district-officer {
    .p-dropdown {
        padding: 0;

        .p-dropdown-label {
            border: 0;
        }
    }
}

.p-bank-dropdown-panel {
    width: 200px;
}

.discom-dashboard .bar-graph {
    .p-dropdown {
        border-radius: 8px;
        border-color: #D0D5DD;
        padding: 9px 10px;
        min-width: 200px;

        .p-dropdown-label {
            padding: 0;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.3;
            color: #667085;
        }

        .p-dropdown-trigger {
            color: #667085;
        }

        .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
            font-size: 13px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .p-icon-wrapper {
                order: 2;
            }

            span {
                order: 1;
            }
        }
    }
}

.p-govt-build-dialog {
    .p-dropdown {
        padding: 12px 0.5rem;

        .p-dropdown-label {
            font-size: 14px;
            padding: 0;

            &.p-placeholder {
                color: #667085;
            }
        }
    }
}

.add-update-doc {
    .p-dropdown {
        width: 100%;
        min-height: 40px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        padding: 10px 16px;
        align-items: center;

        .p-dropdown-label {
            padding: 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.2;
            color: #000;

            &.p-placeholder {
                color: #959494;
            }
        }

        .p-dropdown-panel .p-dropdown-items {

            .p-dropdown-item,
            .p-dropdown-empty-message {
                font-size: 12px;
            }
        }
    }
}

.approve-feasibility-popup {
    .p-dropdown {
        width: 100%;
        border: 1px solid #D0D5DD;
        padding: 7px 16px;
        border-radius: 5px;

        .p-dropdown-label {
            padding: 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.5;
            color: #000;
        }

        .p-dropdown-panel .p-dropdown-items {

            .p-dropdown-item,
            .p-dropdown-empty-message {
                font-size: 12px;
            }
        }
    }
}

.prime-dropdown {
    .p-dropdown {
        border: 1px solid #015296;
        padding: 8px 20px;

        .p-dropdown-label {
            padding: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2;
            color: #015296;
            margin-right: 10px;
        }

        .p-dropdown-trigger {
            width: fit-content;
            color: #015296;
            margin: 0 0 0 auto;
        }

        .p-dropdown-panel {
            border: 1px solid #E8E8E8;
            padding: 10px 0;

            .p-dropdown-items {
                .p-dropdown-item {
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.2;
                    color: #000;
                }
            }
        }
    }
}

.integration-setting {
    .p-dropdown {
        border-color: #D0D5DD;
        padding: 10px 16px;
        width: 100%;
        border-radius: 8px;

        .p-dropdown-label {
            padding: 0;
            font-size: 12px;
            font-weight: 500;
            color: #1A1A1A;
            border-radius: unset;
        }

        .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
            font-size: 12px;
        }
    }
}

.rp-table-dropdown {
    .p-inputtext {
        padding-left: 15px !important;
    }
}

.discom_incentive_inner {
    .p-overlay {
        z-index: 0 !important;
    }
}

.rp-form-group {
    .p-dropdown {
        width: 100%;

        .p-dropdown-label {
            &.p-placeholder {
                color: $inputPlaceholderTextColor;
            }
        }
    }
}

.add-update-sec {
    .p-dropdown {
        width: 100%;
        padding: 7px 10px;
        border: 1px solid $srt-neutral-2400;
        border-radius: 8px;

        .p-dropdown-label {
            padding: 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.5;
            color: $srt-black;
        }

        .p-dropdown-label.p-inputtext.p-placeholder {
            color: $srt-neutral-2300;
        }

        .p-icon-wrapper {
            align-items: center;
        }

        .p-dropdown-clear-icon {
            position: unset;
            margin: 0 auto;
        }

        .p-dropdown-item {
            font-size: 12px;
            line-height: 1.2;
            white-space: break-spaces;
        }

        .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
            border: unset;
        }
    }
}

.subsidy-filters {
    &.rp-form-group {
        .p-dropdown {
            .p-dropdown-clear-icon {
                right: 3.5rem;
            }
        }
    }
}

.change-request-table-admin {
    .rp-dropdown-filter {
        width: 100%;
        display: block;

        .p-dropdown {
            width: 100%;
        }
    }
}

.rp-dropdown-height-fix .p-scroller-viewport .p-scroller{
    height: 200px !important;
}