@use 'sass:math';

.p-multiselect {
    background: $inputBg;
    border: $inputBorder;
    transition: $formElementTransition;
    border-radius: $borderRadius;
    padding: 0.25rem 0.5rem;

    &:not(.p-disabled):hover {
        border-color: $inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
        @include focused-input();
    }

    .p-multiselect-label {
        padding: $inputPadding;
        transition: $formElementTransition;

        &.p-placeholder {
            color: $inputPlaceholderTextColor;
        }
    }

    &.p-multiselect-chip {
        .p-multiselect-token {
            padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
            margin-right: $inlineSpacing;
            background: $chipBg;
            color: $chipTextColor;
            border-radius: $chipBorderRadius;

            .p-multiselect-token-icon {
                margin-left: $inlineSpacing;
            }
        }
    }

    .p-multiselect-trigger {
        background: transparent;
        color: $inputIconColor;
        width: $inputGroupAddOnMinWidth;
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }

    &.p-variant-filled {
        background: $inputFilledBg;

        &:not(.p-disabled):hover {
            background-color: $inputFilledHoverBg;
        }

        &:not(.p-disabled).p-focus {
            background-color: $inputFilledFocusBg;
        }
    }
}

.p-inputwrapper-filled {
    .p-multiselect {
        &.p-multiselect-chip {
            .p-multiselect-label {
                padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
            }
        }
    }
}
    
.p-multiselect-clearable {
    .p-multiselect-label-container {
        padding-right: nth($inputPadding, 2) + $primeIconFontSize;
    }

    .p-multiselect-clear-icon {
        color: $inputIconColor;
        right: $inputGroupAddOnMinWidth;
    }
}

.p-multiselect-panel {
    background: $inputOverlayBg;
    color: $inputListTextColor;
    border: $inputOverlayBorder;
    border-radius: $borderRadius;
    box-shadow: $inputOverlayShadow;

    .p-multiselect-header {
        padding: $inputListHeaderPadding;
        border-bottom: $inputListHeaderBorder;
        color: $inputListHeaderTextColor;
        background: $inputOverlayHeaderBg;
        margin: $inputListHeaderMargin;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;

        .p-multiselect-filter-container {
            .p-inputtext {
                padding-right: nth($inputPadding, 2) + $primeIconFontSize;
            }

            .p-multiselect-filter-icon {
                right: nth($inputPadding, 2);
                color: $inputIconColor;
            }
        }

        .p-checkbox {
            margin-right: $inlineSpacing;
        }

        .p-multiselect-close {
            margin-left: $inlineSpacing;
            @include action-icon();
        }
    }

    .p-multiselect-items {
        padding: $inputListPadding;

        .p-multiselect-item {
            margin: $inputListItemMargin;
            padding: $inputListItemPadding;
            border: $inputListItemBorder;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
            transition: $listItemTransition;
            border-radius: $inputListItemBorderRadius;

            &:first-child {
                margin-top: 0;
            }

            &.p-highlight {
                color: $highlightTextColor;
                background: $highlightBg;

                &.p-focus {
                    background: $highlightFocusBg;
                }
            }

            &:not(.p-highlight):not(.p-disabled) {
                &.p-focus {
                    color: $inputListItemTextFocusColor;
                    background: $inputListItemHoverBg;
                }

                &:hover {
                    color: $inputListItemTextHoverColor;
                    background: $inputListItemHoverBg;
                }
            }

            .p-checkbox {
                margin-right: $inlineSpacing;
            }
        }

        .p-multiselect-item-group {
            margin: $submenuHeaderMargin;
            padding: $submenuHeaderPadding;
            color: $submenuHeaderTextColor;
            background: $submenuHeaderBg;
            font-weight: $submenuHeaderFontWeight;
        }

        .p-multiselect-empty-message {
            padding: $inputListItemPadding;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
        }
    }
}

.p-input-filled {
    .p-multiselect {
        background: $inputFilledBg;

        &:not(.p-disabled):hover {
            background-color: $inputFilledHoverBg;
        }
    
        &:not(.p-disabled).p-focus {
            background-color: $inputFilledFocusBg;
        }
    }

}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
    @include invalid-input();
}

.serial-number-dialog {
    .p-multiselect {
        width: 100%;
        border-color: #D0D5DD !important;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        padding: 12px 12px 12px 45px;
        box-shadow: unset !important;

        p-multiselect-trigger {
            color: #959494;
        }
    }

    .p-multiselect-filter-container .p-inputtext, 
    .p-multiselect-empty-message,
    .p-multiselect-item {
        font-size: 12px;
    }

    .p-multiselect-item{
        .pi-plus {
            font-size: 12px;
        }
    }

    .p-multiselect-label {
        padding: 0;
    }
}

.discom-dashboard {
    .p-multiselect {
        .p-multiselect-label {
            font-size: 12px;
            color: #667085;
        }

        .p-multiselect-trigger {
            color: #667085;
        }

        .p-multiselect-panel {
            .p-multiselect-items .p-multiselect-item, 
            .p-multiselect-items .p-multiselect-empty-message, 
            .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
                font-size: 13px;
            }
        }

        .p-checkbox, .p-checkbox-box {
            width: 15px;
            height: 15px;
        }
    }
}

.add-update-doc {
    .p-multiselect {
        width: 100%;
        min-height: 40px;
        border: 1px solid #D0D5DD !important;
        border-radius: 8px;
        padding: 10px 16px;

        .p-multiselect-label {
            padding: 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.5;
            color: #000;

            &.p-placeholder {
                color: #959494;
            }
        }

        .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
            font-size: 12px;
        }
    }
}
