.p-accordion {
    .p-accordion-header {
        .p-accordion-header-link {
            padding: $accordionHeaderPadding;
            border: $accordionHeaderBorder;
            color: $primaryColor;
            font-size: 20px;
            background: var(--bg-sitemap-page);
            font-weight: $accordionHeaderFontWeight;
            transition: $listItemTransition;
            @media only screen and (max-width: 992px){
                font-size: 14px;
                padding: .625rem;
            }

            .p-accordion-toggle-icon {
                margin-right: $inlineSpacing;
                position: absolute;
                right: 10px;
                transform: rotate(90deg);
                color: var(--heading-color);
            }
        }

        &:not(.p-disabled).p-highlight {
            .p-accordion-header-link {
                background: var(--bg-sitemap-page);
                border-color: $accordionHeaderActiveBorderColor;
                color: $accordionHeaderTextActiveColor;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .p-accordion-content {
        padding: $accordionContentPadding;
        border: 1px solid var(--border-input) ;
        background: var(--bg-color);
        color: var(--text-color);
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        @media only screen and (max-width: 992px){
            padding: .625rem;
        }
    }

    p-accordiontab {
        .p-accordion-tab {
            margin-bottom: 1rem;
        }

        @if $accordionSpacing ==0 {

            &:not(:first-child) {
                .p-accordion-header {
                    .p-accordion-header-link {
                        border-radius: 10px;
                    }
                }
            }

            &:first-child {
                .p-accordion-header {
                    .p-accordion-header-link {
                        border-top-right-radius: 10px;
                        border-top-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                }
            }
        }
    }
}

.p-accordion-header-text {
    color: var(--text-color);
    font-size: 20px;
    line-height: 0.7 !important;
    font-weight: 500;
    text-transform: uppercase;
    @media only screen and (max-width: 767px){
        font-size: 14px;
        margin-right: 0px;
        line-height: 1 !important;
    }
}

.p-accordion-tab-active{
    .p-accordion-toggle-icon {
        transform: rotate(180deg) !important;
    }
}

.new-app-form{
    .p-accordion-header-text{
        text-transform: none;
        font-size: 20px;
        font-weight: 600;
        @media only screen and (max-width: 767px){
            font-size: 16px;
            font-weight: 500;
        }
    }
    .p-accordion .p-accordion-header .p-accordion-header-link{
        background: white;
    }
    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
        background: white;
    }
}

.submit-detail {
    .p-accordion {
        .p-accordion-tab {
            .p-accordion-header {
                .p-accordion-header-link {
                    padding: 14px 0;
                    border: unset;
                    border-bottom: 1px solid $srt-neutral-2400;
                    border-radius: 0;
                    background-color: unset;

                    .p-accordion-header-text {
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 1.5;

                        @media screen and (max-width: 1400px) {
                            font-size: 16px;
                        }
                        
                        @media screen and (max-width: 1200px) {
                            font-size: 14px;
                        }
                    }
                }
            }

            .p-accordion-content {
                padding: 18px 0;
                border: 0;

                label, h3 {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.5;
                    color: $srt-black;
                    margin-bottom: 8px;
            
                    @media screen and (max-width: 1400px) {
                        font-size: 12px;
                        margin-bottom: 5px;
                    }
                }

                h3 {
                    font-size: 16px;

                    @media screen and (max-width: 1400px) {
                        font-size: 14px;
                    }
                }

                .box {
                    padding: 12px;
                    border: 1px solid $srt-neutral-2400;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;

                    @media screen and (max-width: 1400px) {
                        padding: 10px;
                    }

                    h5 {
                        display: block;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.5;
                        color: $srt-black;
                    }

                    span {
                        display: block;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 1.5;
                        color: $srt-neutral-2500;
                    }
                    
                    .pdf {
                        margin-right: 14px !important;
                    }

                    .trash {
                        margin-left: auto !important;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.dashboard-app{
    .p-accordion-header{
        a{
            color: #015296;
        }
    }
    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
        color: #015296;
        font-weight: 500;
    }
    .p-accordion .p-accordion-header .p-accordion-header-link{
        padding: 8px 16px;
        font-weight: 500;
    }
}