.p-progressbar {
    border: $progressBarBorder;
    height: $progressBarHeight;
    background: $progressBarBg;
    border-radius: 5px;

    .p-progressbar-value {
        border: 0 none;
        margin: 0;
        background: $progressBarValueBg;
    }

    .p-progressbar-label {
        display: none !important;
    }
}

.appl-progress{
    .p-progressbar{
        height: 7px;
    }
    .p-progressbar-determinate .p-progressbar-value {
        height: 7px;
    }
}

.prgbar-container{
    align-content: center; 
    margin-top:-80px;
    .p-progressbar {
        width: 94px;
        margin-left: -46px;
        height: 10px;
    }
}

.spinner-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10001;
  }

  .full-screen-overlay {
      top: 0;
      left: 0;
      z-index: 1102;
  }
  
  .spinner .p-progress-spinner-circle {
    animation: custom-spinner-dash 5.5s ease-in-out infinite,
               custom-spinner-color 6s ease-in-out infinite;
  }
  
  @keyframes custom-spinner-dash {
    0% { stroke-dasharray: 1, 200; stroke-dashoffset: 0; }
    50% { stroke-dasharray: 100, 200; stroke-dashoffset: -15; }
    100% { stroke-dasharray: 100, 200; stroke-dashoffset: -125; }
  }
  
  @keyframes custom-spinner-color {
    100%, 0% { stroke: #157a8f; }
  }
  
