p-inputnumber.ng-dirty.ng-invalid>.p-inputnumber>.p-inputtext {
    @include invalid-input();
}

p-inputnumber.p-inputnumber-clearable {
    width: 100%;
    .p-inputnumber-input {
        padding-left: nth($inputPadding, 2) * 2 + $primeIconFontSize;
    }

    .p-inputnumber-clear-icon {
        color: $inputIconColor;
        right: nth($inputPadding, 2);
    }
}

p-inputnumber.p-inputnumber-clearable {
    .p-inputnumber-buttons-stacked {
        .p-inputnumber-clear-icon {
            right: $buttonIconOnlyWidth + nth($inputPadding, 2);
        }
    }

    .p-inputnumber-buttons-horizontal {
        .p-inputnumber-clear-icon {
            right: $buttonIconOnlyWidth + nth($inputPadding, 2);
        }
    }
}

p-inputnumber.p-inputnumber {
    &.p-variant-filled>.p-inputnumber-input {
        background-color: $inputFilledBg;

        &:enabled:hover {
            background-color: $inputFilledHoverBg;
        }

        &:enabled:focus {
            background-color: $inputFilledFocusBg;
        }
    }
}

.complete-profile-dialog,
.consumer-login,
.new-app-form,
.submit-detail {
    .p-inputnumber {
        width: 100%;
    }
}

.vendor-submit-installation {
    .p-inputwrapper {
        .p-inputnumber {
            width: 100%;
        }
    }
}

.p-admin-mobile-input {
    width: 100%;
    
    .p-inputnumber {
        width: 100%;

        .p-inputtext {
            padding: 8px 30px;
            border: 1px solid #D8DBE3;
            background: #FFFFFF;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            color: #000;

            &::placeholder {
                font-size: 14px;
                color: #959494;
            }
        }
    }
}

.approve-feasibility-popup {
    .p-inputnumber .p-inputnumber-input {
        border: 1px solid #D0D5DD;
        padding: 7px 16px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #000;
    }
}

.rp-input-number{
    width: 100%;
    .p-inputnumber{
        width: 100%;
    }
}