.p-paginator {
    background-color: unset;
    justify-content: flex-end !important;
    padding: 12px 20px;
    gap: 10px;

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
        background-color: $srt-neutral-700;
        min-width: 28px;
        height: 28px;
        margin: 0;
        border-radius: 6px;
        border: 1px solid rgba(70, 79, 96, 0.24);

        .p-icon {
            width: 14px;
            height: 14px;
            color: $srt-neutral-500;
        }

        &.p-disabled {
            .p-icon {
                color: $srt-neutral-1000;
            }
        }

        &:not(.p-disabled):not(.p-highlight):hover {
            background: $paginatorElementHoverBg;
            border-color: $paginatorElementHoverBorderColor;
            color: $paginatorElementIconHoverColor;
        }
    }

    .p-paginator-first {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .p-paginator-last {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .p-dropdown {
        background-color: unset;
        height: unset;
        margin: 0;
        border: 0;
        padding: 0;
        align-items: center;
        gap: 2px;

        .p-dropdown-label {
            padding: 0;
            color: $srt-neutral-600;
            font-size: 14px;
        }
    }

    .p-dropdown-trigger {
        width: 16px;
        height: 16px;

        .p-icon {
            width: 14px;
            height: 14px;
            color: $srt-neutral-600;
        }
    }

    .p-paginator-page-input {
        margin-left: $inlineSpacing;
        margin-right: $inlineSpacing;

        .p-inputtext {
            max-width: $paginatorElementWidth;
        }
    }

    .p-paginator-current {
        background-color: $paginatorElementBg;
        border: $paginatorElementBorder;
        color: $paginatorElementIconColor;
        min-width: $paginatorElementWidth;
        height: $paginatorElementHeight;
        margin: $paginatorElementMargin;
        padding: 0 $inlineSpacing;
    }

    .p-paginator-pages {
        .p-paginator-page {
            background-color: unset;
            border-color: unset;
            color: $srt-neutral-600;
            font-size: 14px;
            padding: 0 7px;
            margin: 0;
            &.p-link {
                border-radius: 6px;
            }
            &.p-highlight {
                background-color: $rp-primary-blue-200;
                color: $primaryColor;
                min-width: 28px;
                height: 28px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            &:after {
                // content: '/';
              
            }

            &:not(.p-highlight):hover {
                background: $paginatorElementHoverBg;
                border-color: $paginatorElementHoverBorderColor;
                color: $paginatorElementIconHoverColor;
            }
        }
    }
}
