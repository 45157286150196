@use 'sass:math';

.p-slider {
    background: #E8F3FF;
    border: $sliderBorder;
    border-radius: 5px;

    &.p-slider-horizontal {
        height: $sliderHorizontalHeight;
        width: 100%;
        margin: 0.5rem 0 1.5rem;

        .p-slider-handle {
            margin-top: math.div(-1 * $sliderHandleHeight, 2);
            margin-left: math.div(-1 * $sliderHandleWidth, 2);
        }
    }

    &.p-slider-vertical {
        height: 100%;
        width: $sliderVerticalWidth;

        .p-slider-handle {
            height: $sliderHandleWidth;
            width: $sliderHandleHeight;
            margin-left: math.div(-1 * $sliderHandleWidth, 2);
            margin-bottom: math.div(-1 * $sliderHandleHeight, 2);
        }
    }

    .p-slider-handle {
        height: $sliderHandleHeight;
        width: $sliderHandleWidth;
        background-image: url('../../../images/slider-handle.png');
        transition: $formElementTransition;

        &:focus {
            outline: none;
        }
    }

    .p-slider-range {
        background: $sliderRangeBg;
        border-radius: 5px 0 0 5px;
    }

    &.p-slider-animate {
        &.p-slider-horizontal {
            .p-slider-handle {
                transition: $formElementTransition, left $transitionDuration;
            }

            .p-slider-range {
                transition: width $transitionDuration;
            }
        }

        &.p-slider-vertical {
            .p-slider-handle {
                transition: $formElementTransition, bottom $transitionDuration;
            }

            .p-slider-range {
                transition: height $transitionDuration;
            }
        }
    }
}
