@use 'sass:math';

.p-steps {
    background-color: #f5f5f5;
    border:1px solid $primaryGrayColor;
    padding: 1rem;
    border-radius: 10px;
    width: 96%;
    margin: 0 auto;
    .p-steps-item {
        .p-menuitem-link {
            background: transparent;
            transition: $listItemTransition;

            .p-steps-number {
                color: $stepsItemNumberColor;
                border: $stepsItemBorder;
                background: $stepsItemBg;
                min-width: $stepsItemNumberWidth;
                height: $stepsItemNumberHeight;
                line-height: $stepsItemNumberHeight;
                font-size: $stepsItemNumberFontSize;
                z-index: 1;
                border-radius: $stepsItemNumberBorderRadius;
            }

            .p-steps-title {
                margin-top: $inlineSpacing;
                color: $primaryBlackColor;
                font-weight: 600;
            }

            &:not(.p-disabled):focus-visible {
                @include focused();
            }
        }

        &.p-highlight {
            .p-steps-number {
                background: $primaryWhiteColor;
                color: $primaryWhiteColor;
                border:5px solid #FF9900;
            }

            .p-steps-title {
                font-weight: $stepsItemActiveFontWeight;
                color: $textColor;
            }
        }

        &.p-completed{
            .p-steps-number {
                border:5px solid #1FA822;
            }
        }

        &:before {
            content:' ';
            border-top: 2px dashed #afafaf;
            width: 100%;
            top: 50%;
            left: 50%;
            display: block;
            position: absolute;
            margin-top: -12.5px;
            margin-left: 7px;
        }

        &:last-child{
            margin-left: 2px;
            &::before{
                border-top:2px solid transparent;
            }
        }

        &.p-disabled{
            opacity: 1;
        }
    }
}

