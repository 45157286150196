
  

  /* poppins-100 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/poppins-v21-latin-100.woff2') format('woff2');
  }
  /* poppins-200 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/poppins-v21-latin-200.woff2') format('woff2');
  }
  /* poppins-300 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/poppins-v21-latin-300.woff2') format('woff2');
  }
  /* poppins-regular - latin */
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/poppins-v21-latin-regular.woff2') format('woff2');
  }
  /* poppins-500 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/poppins-v21-latin-500.woff2') format('woff2');
  }
  /* poppins-600 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/poppins-v21-latin-600.woff2') format('woff2');
  }
  /* poppins-700 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/poppins-v21-latin-700.woff2') format('woff2');
  }
  /* poppins-800 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/poppins-v21-latin-800.woff2') format('woff2');
  }
  /* poppins-900 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/poppins-v21-latin-900.woff2') format('woff2');
  }
  
  .fw100{
    font-weight: 100;
  }
  .fw200{
    font-weight: 200;
  }
  .fw300{
    font-weight: 300;
  }
  .fw400{
    font-weight: 400;
  }
  .fw500{
    font-weight: 500;
  }
  .fw600{
    font-weight: 600;
  }
  .fw700{
    font-weight: 700;
  }
  .fw800{
    font-weight: 800;
  }
  .fw900{
    font-weight: 900;
  }