@use 'sass:math';
@import "../../../../assets/themes/mytheme/variables.scss";

.p-speeddial-button {
    &.p-button.p-button-icon-only {
        width: $speedDialButtonWidth;
        height: $speedDialButtonHeight;

        .p-button-icon {
            font-size: $speedDialButtonIconFontSize;
        }

        .p-icon {
            width: $speedDialButtonIconFontSize;
            height: $speedDialButtonIconFontSize;
        }
    }
}

.p-speeddial-list {
    outline: 0 none;
}

.p-speeddial-item {
    &.p-focus > .p-speeddial-action {
        @include focused();
    }
}

.p-speeddial-action {
    width: $speedDialActionWidth;
    height: $speedDialActionHeight;
    background: $speedDialActionBg;
    color: $speedDialActionTextColor;

    &:hover {
        background: $speedDialActionHoverBg;
        color: $speedDialActionTextHoverColor;
    }
}

.p-speeddial-direction-up {
    .p-speeddial-item {
        margin: math.div($inlineSpacing, 2) 0;

        &:first-child {
            margin-bottom: $inlineSpacing;
        }
    }
}

.p-speeddial-direction-down {
    .p-speeddial-item {
        margin: math.div($inlineSpacing, 2) 0;

        &:first-child {
            margin-top: $inlineSpacing;
        }
    }
}

.p-speeddial-direction-left {
    .p-speeddial-item {
        margin: 0 math.div($inlineSpacing, 2);

        &:first-child {
            margin-right: $inlineSpacing;
        }
    }
}

.p-speeddial-direction-right {
    .p-speeddial-item {
        margin: 0 math.div($inlineSpacing, 2);

        &:first-child {
            margin-left: $inlineSpacing;
        }
    }
}

.p-speeddial-circle,
.p-speeddial-semi-circle,
.p-speeddial-quarter-circle {
    .p-speeddial-item {
        margin: 0;

        &:first-child,
        &:last-child {
            margin: 0;
        }
    }
}

.p-speeddial-mask {
    background-color: $maskBg;
}

.quick-link {
    .p-speeddial {
        position: unset;
        min-width: 364px;
        align-items: end;
        gap: 8px;

        .p-speeddial-button {
            background-color: $srt-neutral-2900;
            border: 3px solid $srt-white;
            box-shadow: 0px 0px 71.3px 0px rgba(0, 0, 0, 0.251);
            width: fit-content;
            height: fit-content;
            border-radius: 62px;
            padding: 10px 20px;

            .p-button {
                background-color: unset;
                width: 100%;
                border: unset;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.5;
                gap: 10px;
                padding: 0;
                color: $srt-white;

                .p-button-icon.pi {
                    width: 18px;
                    height: 18px;
                    justify-content: center;
                    align-items: center;
                    display: grid !important;
                    font-size: 18px;
                }
            }
        }

        .p-speeddial-list {
            gap: 8px;

            .p-speeddial-item {
                min-width: 167px;
                margin: 0;

                .p-speeddial-action {
                    background-color: $srt-neutral-2900;
                    width: 100%;
                    border-radius: 10px;
                    gap: 10px;
                    border: unset;
                    justify-content: left;
                    height: fit-content;
                    padding: 10px;

                    .p-button-icon {
                        font-size: 18px;
                    }

                    .p-speeddial-action-text {
                        font-size: 14px;
                        font-weight: 500;
                        color: $srt-white;
                        line-height: 1.5;
                    }
                }
            }
        }

        &.p-speeddial-opened {
            .p-speeddial-rotate {
                transform: unset;
                background-color: $srt-white;
                border: 3px solid $srt-neutral-2900;

                .p-button {
                    color: $srt-neutral-2900;
                }
            }
        }
    }
}